import Link from 'next/link';

import { Typography } from '@/components/_atoms/Typography';
import { schemas } from '@/network/client';
import { cn } from '@/utils/styles.utils';

type GroupPath = schemas['GroupPath'];

interface Props extends GroupPath {
  closeMenu: () => void;
  columns?: boolean;
}

export const HeaderMobileMenuList = (props: Props) => {
  const { group_name: name, values, columns, closeMenu } = props;
  return (
    <div>
      <Typography variant="body_l" bold className="mb-4">
        {name}
      </Typography>
      <ul
        className={cn({
          'columns-2': !!columns,
        })}
      >
        {values.map((value) => (
          <li key={value.path} className="mb-4">
            <Link href={value.path} onClick={closeMenu} className="flex">
              <Typography as="span" variant="body_m" color="grey-standard">
                {value.name}
              </Typography>
              <Typography as="span" className="ml-1">
                »
              </Typography>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
