import { useTranslations } from 'next-intl';
import React from 'react';

import { schemas } from '@/network/client';

import { RecommendedCarousel } from './RecommendedCarousel';

interface Props {
  data: schemas['MainPageTyreModel'][];
}

export const RecommendedSection = (props: Props) => {
  const t = useTranslations();
  const { data } = props;
  return (
    <>
      <p className="mb-6 text-header-l font-bold laptop:mb-10 laptop:text-header-xl">
        {t('homeScreen.recommended.header')}
      </p>
      <RecommendedCarousel data={data} />
    </>
  );
};
