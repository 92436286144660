import { useTranslations } from 'next-intl';

import settings from '@/static/settings.static.json';
import { cn } from '@/utils/styles.utils';

interface Props {
  light?: boolean;
}

export const OpeningHours = (props: Props) => {
  const t = useTranslations();
  const { light } = props;
  const isHotlineAvailableOnSaturday = !!settings.saturday_start && !!settings.saturday_end;
  return (
    <span
      className={cn('text-body-m text-text-grey-dark', {
        'text-text-grey-super-light': !!light,
      })}
    >
      {t('contact.openHours.mondayToFriday', {
        start: settings.monday_friday_start,
        end: settings.monday_friday_end,
      })}
      {isHotlineAvailableOnSaturday ? (
        <span className="ml-2">
          {t('contact.openHours.saturday', {
            start: settings.saturday_start,
            end: settings.saturday_end,
          })}
        </span>
      ) : null}
    </span>
  );
};
