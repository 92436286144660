import { NumberFormatOptions, useFormatter } from 'next-intl';

export const usePrice = () => {
  const format = useFormatter();
  return (value: number | string, options?: NumberFormatOptions) => {
    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
    if (Number.isNaN(parsedValue)) {
      return '---';
    }

    return format.number(parsedValue, {
      style: 'currency',
      currency: 'PLN',
      currencyDisplay: 'narrowSymbol',
      // To keep spaces in price
      useGrouping: 'always',
      ...options,
    });
  };
};

export const usePrettyPrice = () => {
  const format = useFormatter();
  return (value: number | string, options?: NumberFormatOptions) => {
    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
    if (Number.isNaN(parsedValue)) {
      return '---';
    }
    return format.number(parsedValue, {
      style: 'decimal',
      // To keep spaces in price
      useGrouping: 'always',
      minimumFractionDigits: parsedValue % 1 !== 0 ? 2 : 0,
      ...options,
    });
  };
};

export const usePriceParts = () => {
  const format = useFormatter();

  return (value: number | string) => {
    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
    if (Number.isNaN(parsedValue)) {
      return ['---', '---'];
    }
    const parsed = format.number(parsedValue, {
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      // To keep spaces in price
      useGrouping: 'always',
    });
    return parsed.split(',');
  };
};
