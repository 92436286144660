import React from 'react';

import { Grid } from '@/components/_atoms/Grid';
import { Typography } from '@/components/_atoms/Typography';
import { schemas } from '@/network/client';

import { ProducersSectionCarousel } from './ProducersSectionCarousel';

interface Props {
  data: schemas['MainPageProducer'][];
}

export const ProducersSection = (props: Props) => {
  const { data } = props;
  return (
    <>
      <Grid className="mb-8 laptop:mb-14">
        <Grid.Item mobile={12} laptop={6}>
          <Typography
            as="p"
            bold
            id="homeScreen.producers.header"
            className="mb-4 text-header-l laptop:mb-10 laptop:text-header-xl"
          />
          <Typography variant="body_xl" id="homeScreen.producers.text" color="grey-standard" />
        </Grid.Item>
      </Grid>
      <ProducersSectionCarousel producers={data} />
    </>
  );
};
