import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Button } from '../Button';
import { ModalClose } from '../Modal';
import { ModalCard } from '../ModalCard';

import { ModalConfirmData } from './Modals';

type ModalConfirmProps = {
  modal: ModalConfirmData;
  close: () => void;
};

export const ModalConfirm = ({ modal, close }: ModalConfirmProps) => {
  const t = useTranslations();
  const [isLoading, setIsLoading] = useState(false);
  const onClickConfirm = async () => {
    const promise = modal.onConfirm?.();
    if (promise instanceof Promise) {
      setIsLoading(true);
      await promise;
      setIsLoading(false);
    }
    close();
  };
  return (
    <>
      <ModalCard title={modal.title} text={modal.description} close={close} size="s">
        <div className="space-x-2">
          <Button
            variant="subtle"
            {...(modal.buttonsProps?.confirm ?? {})}
            loading={isLoading}
            onClick={onClickConfirm}
            text={modal.labels?.confirm ?? t('commons.confirmModal.labelConfirm')}
          />
          <ModalClose>
            <Button
              variant="subtle"
              text={modal.labels?.cancel ?? t('commons.confirmModal.labelCancel')}
              onClick={modal.onCancel}
              {...(modal.buttonsProps?.cancel ?? {})}
            />
          </ModalClose>
        </div>
      </ModalCard>
    </>
  );
};
