import Image from 'next/image';
import React from 'react';

import { Typography } from '@/components/_atoms/Typography';
import { schemas } from '@/network/client';
import { cn } from '@/utils/styles.utils';

import { PostItemTime } from '../PostItemTime';

export const PostItemSmall = (props: schemas['PostList']) => {
  const { image, title, created_at, read_time } = props;

  return (
    <div className="flex">
      <div className="mr-6 inline-flex overflow-hidden rounded-lg">
        {image && (
          <Image
            src={image}
            width={112}
            height={75}
            alt={title}
            style={{
              objectFit: 'cover',
            }}
          />
        )}
      </div>
      <div className={cn('flex flex-1 flex-col justify-between')}>
        <Typography variant="body_l" bold className="line-clamp-2">
          {title}
        </Typography>
        <div className="mb-1">
          <PostItemTime createdAt={created_at} readTime={read_time} />
        </div>
      </div>
    </div>
  );
};
