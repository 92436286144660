import { useTranslations } from 'next-intl';
import Link from 'next/link';
import React from 'react';

import { Container } from '@/components/_atoms/Container';
import { DELIVERY_STATIC_PAGE_PATH, INFO_FOR_CLIENTS_STATIC_PAGE_PATH } from '@/logic/staticPage';

import { HeaderContact } from '../HeaderContact';

export const HeaderTop = () => {
  const t = useTranslations();
  return (
    <div className="bg-grey-1000">
      <Container>
        <div className={'flex h-8 items-center justify-between'}>
          <ul className="inline-flex h-full">
            <li className="mr-12 inline-flex h-full items-center">
              <Link
                href={DELIVERY_STATIC_PAGE_PATH}
                className="text-body-xs text-text-grey-super-light hover:text-text-white"
              >
                {t('headerTop.delivery')}
              </Link>
              <span className="ml-2 text-body-xs font-bold text-text-orange">{t('headerTop.deliveryFree')}</span>
            </li>
            <li className="mr-12 inline-flex h-full items-center">
              <Link
                href={INFO_FOR_CLIENTS_STATIC_PAGE_PATH}
                className="text-body-xs text-text-grey-super-light hover:text-text-white"
              >
                {t('headerTop.infoForCustomer')}
              </Link>
            </li>
            <li className="mr-12 inline-flex h-full items-center">
              <Link href="/blog" className="text-body-xs text-text-grey-super-light hover:text-text-white">
                {t('headerTop.blog')}
              </Link>
            </li>
          </ul>
          <HeaderContact />
        </div>
      </Container>
    </div>
  );
};
