import { useFormatter } from 'next-intl';
import React from 'react';

import { Typography } from '@/components/_atoms/Typography';

interface Props {
  createdAt: string;
  readTime?: number;
}

export const PostItemTime = (props: Props) => {
  const { createdAt, readTime = 0 } = props;

  const format = useFormatter();
  const dateStr = format.dateTime(new Date(createdAt), {
    dateStyle: 'long',
  });

  const minutes = format.number(readTime, {
    style: 'unit',
    unit: 'minute',
  });

  return (
    <div>
      <Typography as="span" variant="body_s" color="grey-standard">
        {dateStr}
      </Typography>
      <Typography as="span" variant="body_s" color="grey-light" className="ml-1 mr-1">
        •
      </Typography>
      <Typography as="span" variant="body_s" color="grey-standard">
        {minutes}
      </Typography>
    </div>
  );
};
