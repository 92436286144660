import { useTranslations } from 'next-intl';
import { Dispatch, SetStateAction } from 'react';

import { SelectCarValue, useSelectCar } from '@/logic/selectCar';
import { cn } from '@/utils/styles.utils';

import { Spinner } from '../_atoms/Spinner';
import { Select } from '../_molecules/Select';

import { useGroupSelectCarProducers } from './useGroupSelectCarProducers';

interface Props {
  value?: SelectCarValue | null;
  onChange: Dispatch<SetStateAction<SelectCarValue | null>>;
  type?: 'home' | 'normal';
  small?: boolean;
}

export const SelectCar = (props: Props) => {
  const t = useTranslations();
  const { value: selectCarValue, onChange, type = 'normal' } = props;

  const {
    producers = [],
    models = [],
    generations = [],
    engines = [],
    selectCarOnChange,
    selectCarIsProcessing,
  } = useSelectCar(selectCarValue || null, onChange);
  const selectedModel = models.find((model) => model.slug === selectCarValue?.model);
  const producerOptions = useGroupSelectCarProducers(producers);
  const modelOptions = models.map((model) => ({ value: model.slug, label: model.shop_name || '' }));
  const yearOptions = [...(selectedModel?.years || [])].reverse().map((year) => ({ value: year, label: year }));
  const generationOptions = generations.map((generation) => ({
    value: generation.generation_slug,
    label: generation.generation_shop_name || '',
  }));
  const engineOptions = engines.map((engine) => ({ value: engine.modification_slug, label: engine.engine }));

  const changeProducer = (value = '') => selectCarOnChange(value || '', 'producer');
  const changeModel = (value = '') => selectCarOnChange(value || '', 'model');
  const changeYear = (value = '') => selectCarOnChange(value || '', 'year');
  const changeGeneration = (value = '') => selectCarOnChange(value || '', 'generation');
  const changeEngine = (value = '') => selectCarOnChange(value, 'engine');

  const isHome = type === 'home';
  const variant = isHome ? 'empty' : 'solid';

  const itemClassName = cn('border-grey-300', {
    'flex-[2] border-b laptop:border-b-0 laptop:border-r': isHome,
  });
  return (
    <div className="relative flex-1">
      <Spinner visible={selectCarIsProcessing} overflow center />
      <div
        className={cn('flex-1', {
          'flex flex-col flex-wrap laptop:flex-row': isHome,
          'grid gap-2': type === 'normal',
        })}
      >
        <div className={itemClassName}>
          <Select
            className={cn({
              'hover:bg-initial': isHome,
            })}
            variant={variant}
            value={selectCarValue?.producer}
            onChange={changeProducer}
            options={producerOptions}
            label={t('mainSearchCar.producerLabel')}
            disabled={!producerOptions.length}
          />
        </div>
        <div className={itemClassName}>
          <Select
            className={cn({
              'hover:bg-initial': isHome,
            })}
            variant={variant}
            value={selectCarValue?.model}
            onChange={changeModel}
            options={modelOptions}
            label={t('mainSearchCar.modelLabel')}
            disabled={!selectCarValue?.producer || !modelOptions.length}
          />
        </div>
        <div className={itemClassName}>
          <Select
            className={cn({
              'hover:bg-initial': isHome,
            })}
            variant={variant}
            value={selectCarValue?.year}
            onChange={changeYear}
            options={yearOptions}
            label={t('mainSearchCar.versionLabel')}
            disabled={!selectCarValue?.model || !yearOptions.length}
          />
        </div>
        <div className={itemClassName}>
          <Select
            className={cn({
              'hover:bg-initial': isHome,
            })}
            variant={variant}
            value={selectCarValue?.generation}
            onChange={changeGeneration}
            options={generationOptions}
            label={t('mainSearchCar.generationLabel')}
            disabled={!selectCarValue?.year || !generationOptions.length}
          />
        </div>
        <div className={cn(itemClassName, 'flex-[4]')}>
          <Select
            className={cn({
              'hover:bg-initial': isHome,
            })}
            variant={variant}
            value={selectCarValue?.engine}
            onChange={changeEngine}
            options={engineOptions}
            label={t('mainSearchCar.engineLabel')}
            disabled={!selectCarValue?.generation || !engineOptions.length}
          />
        </div>
      </div>
    </div>
  );
};
