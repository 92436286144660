import { EmblaCarouselType } from 'embla-carousel';

import { cn } from '@/utils/styles.utils';

import { useCarouselDots } from './useCarouselDots';

interface Props {
  emblaApi: EmblaCarouselType | undefined;
}

export const CarouselDots = (props: Props) => {
  const { emblaApi } = props;
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useCarouselDots(emblaApi);

  return (
    <div className="flex h-2 justify-center gap-2">
      {scrollSnaps.map((_, index) => (
        <button
          type="button"
          key={index}
          className={cn('size-2 rounded-full bg-black bg-opacity-20', {
            'bg-opacity-100': selectedIndex === index,
          })}
          onClick={() => onDotButtonClick(index)}
        />
      ))}
    </div>
  );
};
