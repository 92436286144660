import React from 'react';

import { Icon } from '@/components/_atoms/Icon';
import { HeaderLogo } from '@/components/_molecules/HeaderLogo';
import { useModal } from '@/components/_molecules/Modal';

import { HeaderBasketElement } from '../HeaderBasketElement';

import { HeaderMobileMenu } from './_components/HeaderMobileMenu';

// const HeaderMobileMenu = dynamic(() => import('./_components/HeaderMobileMenu').then((mod) => mod.HeaderMobileMenu), {
//   ssr: false,
// });

export const HeaderMobile = () => {
  const modal = useModal();
  return (
    <>
      <div className="flex h-[var(--header-height)] items-center justify-between">
        <button onClick={modal.open} aria-label="Otwórz menu">
          <Icon name="menuBurger" />
        </button>
        <HeaderLogo />
        <HeaderBasketElement device="mobile" />
      </div>
      <HeaderMobileMenu isOpen={modal.isOpen} setIsOpen={modal.setIsOpen} />
    </>
  );
};
