import { useTranslations } from 'next-intl';
import { useEffect } from 'react';

import { useTyreSizeFilters } from '@/hooks/useTyreSizeFilters';
import { schemas } from '@/network/client';
import { cn } from '@/utils/styles.utils';

import { Select } from '../_molecules/Select';

interface Props {
  value: string | null;
  onChange: (size: string | null) => void;
  sizes?: schemas['Sizes'];
  type?: 'home' | 'normal';
  small?: boolean;
}
export const SelectSize = (props: Props) => {
  const t = useTranslations();
  const { value, onChange, sizes, type = 'normal', small = false } = props;
  const { size, widths, rims, profiles, changeTyreSize } = useTyreSizeFilters(value, sizes);
  const rim = size.rim;

  const widthOptions = widths.map((width) => ({ value: width, label: width }));
  const profileOptions = profiles.map((profile) => ({ value: profile, label: profile }));
  const rimsOptions = rims.map((rim) => ({ value: rim.srednica, label: rim.srednica }));

  useEffect(() => {
    if (!rim) {
      onChange(null);
    }
  }, [rim]);

  const changeWidth = (value: string | undefined) => {
    changeTyreSize(value || '', 'width');
  };

  const changeProfile = (value: string | undefined) => {
    changeTyreSize(value || '', 'profile');
  };

  const changeRim = (value: string | undefined) => {
    const newRim = rims.find((_rim) => _rim.srednica === value);
    if (!newRim) {
      return;
    }
    changeTyreSize(newRim.srednica, 'rim');
    onChange(newRim.rozmiar);
  };

  const isHome = type === 'home';
  const variant = isHome ? 'empty' : 'solid';
  const itemClassName = cn({
    'border-b border-grey-300 laptop:w-[160px] laptop:border-b-0 laptop:border-r': isHome,
    'laptop:w-[110px]': !!small,
  });
  return (
    <div
      className={cn({
        'grid gap-2': type === 'normal',
        'flex flex-col flex-wrap laptop:flex-row': isHome,
      })}
    >
      <div className={itemClassName}>
        <Select
          variant={variant}
          value={size.width}
          onChange={changeWidth}
          options={widthOptions}
          label={t('mainSearch.widthLabel')}
          className={cn({
            'hover:bg-initial': isHome,
          })}
        />
      </div>
      <div className={itemClassName}>
        <Select
          variant={variant}
          value={size.profile}
          onChange={changeProfile}
          options={profileOptions}
          label={t('mainSearch.profileLabel')}
          disabled={!size.width}
          className={cn({
            'hover:bg-initial': isHome,
          })}
        />
      </div>
      <div className={itemClassName}>
        <Select
          variant={variant}
          value={size.rim}
          onChange={changeRim}
          options={rimsOptions}
          label={t('mainSearch.rimLabel')}
          disabled={!size.profile}
          className={cn({
            'hover:bg-initial': isHome,
          })}
        />
      </div>
    </div>
  );
};
