import { useTranslations } from 'next-intl';

import { MainSearch } from '@/components/MainSearch';
import { Seo } from '@/components/Seo';
import { HomeStructuredData } from '@/components/StructuredData/HomeStructuredData';
import { Hr } from '@/components/_atoms/Hr';
import { useMainPageQuery } from '@/network/_queries/mainPage.queries';

import { BannersSection } from './components/BannersSection/BannersSection';
import { FaqSection } from './components/FaqSection';
import { HomeTopIcons } from './components/HomeTopIcons';
import { PostsSection } from './components/PostsSection';
import { ProducersSection } from './components/ProducersSection';
import { RecommendedSection } from './components/RecommendedSection';
import { ReviewsSection } from './components/ReviewsSection';
import { TyresSection } from './components/TyresSection';
import { WhyUsSection } from './components/WhyUsSection';

export const HomeScreen = () => {
  const t = useTranslations();
  const { data } = useMainPageQuery();

  if (!data) {
    return null;
  }
  const {
    main_page_tyre_models,
    main_page_producers,
    main_page_posts,
    main_page_reviews,
    banners,
    main_page_meta_description,
    main_page_meta_title,
  } = data;

  return (
    <>
      <Seo title={main_page_meta_title} description={main_page_meta_description} />
      <HomeStructuredData />
      <section className="pt-6 laptop:pb-6 laptop:pt-8">
        <MainSearch main header={t('homeScreen.title')} />
        <HomeTopIcons />
      </section>
      <section>
        <BannersSection data={banners} />
      </section>
      <Hr className="bg-grey-400" />
      <section className="py-8 laptop:py-20">
        <WhyUsSection />
      </section>
      <Hr className="bg-grey-400" />
      <section className="py-8 laptop:py-20">
        <RecommendedSection data={main_page_tyre_models} />
      </section>
      <Hr className="bg-grey-400" />
      <section className="py-8 laptop:py-20">
        <ReviewsSection data={main_page_reviews} />
      </section>
      <Hr className="bg-grey-400" />
      <section className="py-8 laptop:py-20">
        <PostsSection data={main_page_posts} />
      </section>
      <Hr className="bg-grey-400" />
      <section className="py-8 laptop:py-20">
        <ProducersSection data={main_page_producers} />
      </section>
      <Hr className="bg-grey-400" />
      <section className="py-8 laptop:py-20">
        <TyresSection producers={main_page_producers} />
      </section>
      <Hr className="bg-grey-400" />
      <section className="py-8 laptop:py-20">
        <FaqSection />
      </section>
    </>
  );
};
