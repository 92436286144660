import createClient from 'openapi-fetch';

import { components, paths } from './routes-openapi';

export type routesSchemas = components['schemas'];

export const routesClient = createClient<paths>({
  querySerializer: {
    array: {
      style: 'form',
      explode: false,
    },
  },
});
