import Image from 'next/image';

import certificateImg from '@/assets/images/certificates/goodYear.png';
import { Icon } from '@/components/_atoms/Icon';
import { ModalClose, ModalContent, Modal, useModal } from '@/components/_molecules/Modal';

export const FooterCertificate = () => {
  const modal = useModal();
  return (
    <Modal isOpen={modal.isOpen} onOpenChange={modal.setIsOpen}>
      <button
        onClick={modal.open}
        className="relative size-[90px] tablet:size-[110px] laptop:mt-[100px]"
        aria-label="Certyfikat GoodYear"
      >
        <Image alt="" quality={100} src={certificateImg} fill unoptimized />
      </button>
      <ModalContent>
        <div className="relative">
          <ModalClose>
            <button className="absolute right-3 top-3">
              <Icon name="close" size={24} className="fill-white transition-colors hover:fill-grey-500" />
            </button>
          </ModalClose>
          <img src="/goodYearFull.webp" className="max-h-[90vh] max-w-[90vw] rounded-lg" />
        </div>
      </ModalContent>
    </Modal>
  );
};
