import { withScope, captureException } from '@sentry/nextjs';
import { MutationCache, QueryClient, QueryClientConfig } from '@tanstack/react-query';

import { isApiError } from './api.utils';
import { isRequestError } from './client';

export const REACT_QUERY_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      throwOnError: false,
      refetchOnWindowFocus: false,
      retry: 0,
      // With SSR, we usually want to set some default staleTime
      // above 0 to avoid refetching immediately on the client
      staleTime: 20 * 1000,
    },
  },
  // https://aronschueler.de/blog/2022/12/16/generating-meaningful-issues-in-sentry-with-react-query-+-axios/
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      const meta = mutation.options.meta;

      if (!meta?.captureException) {
        return;
      }

      if (
        isApiError(err) &&
        meta?.excludeErrorCodes &&
        Array.isArray(meta.excludeErrorCodes) &&
        meta.excludeErrorCodes.includes(err.data.error_code)
      ) {
        return;
      }
      const error = err as unknown;
      withScope((scope) => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
          responseData: isRequestError(error) ? JSON.stringify(error.data || {}, null, 2) : undefined,
        });
        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            // Duplicate to prevent modification
            Array.from(mutation.options.mutationKey) as string[],
          );
        }
        captureException(err);
      });
    },
  }),
};

export const createQueryClient = () => {
  return new QueryClient(REACT_QUERY_CONFIG);
};
