import { useTranslations } from 'next-intl';
import Link from 'next/link';
import React, { useState } from 'react';

import { Tooltip } from '@/components/_molecules/Tooltip';
import { useCompareTyresStore } from '@/logic/compareTyres';
import { COMPARE_TYRES_MIN_ITEMS } from '@/logic/compareTyres/compareTyres.constatns';
import { Routes } from '@/routes/routes';

import { HeaderAction } from '../HeaderAction/HeaderAction';

export const HeaderCompare = () => {
  const t = useTranslations();
  const [isHover, setIsHover] = useState(false);
  const close = () => setIsHover(false);
  const open = () => setIsHover(true);
  const { compareTyres } = useCompareTyresStore();
  const count = compareTyres.length;
  const active = count > COMPARE_TYRES_MIN_ITEMS;
  const component = (
    <HeaderAction icon="navbarCompare" badge={count} textId="header.actions.compare.text" isHover={isHover} />
  );

  if (active) {
    return (
      <div onMouseEnter={open} onMouseLeave={close}>
        <Link prefetch={false} href={Routes.compare}>
          {component}
        </Link>
      </div>
    );
  }
  return (
    <Tooltip offset={-5} side="bottom" content={t('header.actions.compare.tooltip')}>
      <div onMouseEnter={open} onMouseLeave={close} style={{ height: '100%' }}>
        {component}
      </div>
    </Tooltip>
  );
};
