import Link from 'next/link';
import React from 'react';

import { BREAKPOINT_LAPTOP, BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '@/constants/breakpoints.constants';
import { useAnalyticsBanner } from '@/logic/analytics/useAnalyticsBanner';
import { schemas } from '@/network/client';

export const Banner = (props: schemas['MainPageBanner'] & { index: number }) => {
  const {
    main_page_desktop_banner: desktopBanner,
    main_page_desktop_banner_content: desktopContent,
    main_page_mobile_banner: mobileBanner,
    main_page_mobile_banner_content: mobileContent,
    main_page_tablet_banner: tabletBanner,
    main_page_tablet_banner_content: tabletContent,
    path,
    name,
    index,
  } = props;
  const { ref, onClick } = useAnalyticsBanner(name, index, 'main_page_top_banner');

  const priority = index === 0;

  return (
    <div className="relative h-[480px] tablet:h-[500px] laptop:h-[580px]" ref={ref}>
      <picture>
        {desktopBanner && <source srcSet={desktopBanner} media={`(min-width: ${BREAKPOINT_LAPTOP}px)`} />}
        {tabletBanner && <source srcSet={tabletBanner} media={`(min-width: ${BREAKPOINT_TABLET}px)`} />}
        {mobileBanner && <source srcSet={mobileBanner} media={`(min-width: ${BREAKPOINT_MOBILE}px)`} />}
        <img
          alt="Banner"
          decoding="async"
          src={mobileBanner ?? ''}
          fetchPriority={priority ? 'high' : undefined}
          loading={priority ? undefined : 'lazy'}
          className="absolute inset-0 h-0 max-h-full min-h-full w-0 min-w-full max-w-full object-cover object-bottom"
        />
      </picture>
      {/* 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error  path will be not undefined */}
      <Link href={path} onClick={onClick}>
        <picture>
          {desktopContent && <source srcSet={desktopContent} media={`(min-width: ${BREAKPOINT_LAPTOP}px)`} />}
          {tabletContent && <source srcSet={tabletContent} media={`(min-width: ${BREAKPOINT_TABLET}px)`} />}
          {mobileContent && <source srcSet={mobileContent} media={`(min-width: ${BREAKPOINT_MOBILE}px)`} />}
          <img
            alt="Content"
            decoding="async"
            src={mobileContent ?? ''}
            fetchPriority={priority ? 'high' : undefined}
            loading={priority ? undefined : 'lazy'}
            className="absolute inset-0 h-0 max-h-full min-h-full w-0 min-w-full max-w-full object-contain object-bottom"
          />
        </picture>
      </Link>
    </div>
  );
};
