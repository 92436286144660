import React from 'react';

import { Color } from '@/types/colors.types';
import { IconsName } from '@/types/icons';
import { cn } from '@/utils/styles.utils';

export type Icons = IconsName;
interface IconProps {
  size?: number;
  color?: Color;
  name: Icons;
  className?: string;
  style?: React.CSSProperties;
  badge?: number;
}

const colorMap: Record<Color, string> = {
  'text-dark': 'fill-text-dark',
  'text-light': 'fill-text-light',
  'text-white': 'fill-text-white',
  'text-grey': 'fill-text-grey',
  'text-red': 'fill-text-red',
  white: 'fill-white',
  black: 'fill-black',
  yellow: 'fill-yellow',
  'grey-1000': 'fill-grey-1000',
  'grey-900': 'fill-grey-900',
  'grey-800': 'fill-grey-800',
  'grey-700': 'fill-grey-700',
  'grey-600': 'fill-grey-600',
  'grey-500': 'fill-grey-500',
  'grey-400': 'fill-grey-400',
  'grey-300': 'fill-grey-300',
  'grey-200': 'fill-grey-200',
  'grey-100': 'fill-grey-100',
  'grey-50': 'fill-grey-50',
  'blue-700': 'fill-blue-700',
  'blue-500': 'fill-blue-500',
  'orange-600': 'fill-orange-600',
  'orange-500': 'fill-orange-500',
  'green-400': 'fill-green-400',
  'green-500': 'fill-green-500',
  'red-600': 'fill-red-600',
  'red-500': 'fill-red-500',
  'red-400': 'fill-red-400',
  'red-100': 'fill-red-100',
  'beige-400': 'fill-beige-400',
  'beige-100': 'fill-beige-100',
};

export const Icon = (props: IconProps) => {
  const { size = 24, color = 'grey-1000', badge, name, className, ...restProps } = props;

  const icon = (
    <svg {...restProps} width={size} height={size} className={cn(colorMap[color], className)}>
      <use href={`/icons-sprite.svg#${name}`} />
    </svg>
  );
  if (!badge) {
    return icon;
  }
  return (
    <span className="relative inline-block">
      <span className="absolute right-0 top-0 inline-flex h-[75%] w-[75%] translate-x-[25%] translate-y-[-25%] items-center justify-center rounded-full border-2 border-white bg-grey-1000">
        <span className="text-body-xxs text-white">{badge}</span>
      </span>
      {icon}
    </span>
  );
};
