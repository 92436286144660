import { isRequestError } from './client';
import { operations } from './openapi';

export const ApiErrorCodes = {
  register: {
    EMAIL_ALREADY_EXISTS:
      'EMAIL_ALREADY_EXISTS' satisfies operations['auth_register_create']['responses']['400']['content']['application/json']['error_code'],
  },
  forgotPassword: {
    USER_DOES_NOT_EXIST:
      'USER_DOES_NOT_EXIST' satisfies operations['auth_password_reset_create']['responses']['400']['content']['application/json']['error_code'],
  },
  changePassword: {
    INVALID_OLD_PASSWORD:
      'INVALID_OLD_PASSWORD' satisfies operations['account_user_change_password_create']['responses']['400']['content']['application/json']['error_code'],
  },
  createOrder: {
    VARIANT_SOLD_OUT:
      'VARIANT_SOLD_OUT' satisfies operations['orders_create']['responses']['400']['content']['application/json']['error_code'],
  },
  createUpdateCheckout: {
    VARIANT_SOLD_OUT:
      'VARIANT_SOLD_OUT' satisfies operations['checkout_create']['responses']['400']['content']['application/json']['error_code'],
  },
  getProductSales: {
    VARIANT_SOLD_OUT:
      'VARIANT_SOLD_OUT' satisfies operations['tyres_product_sales_retrieve']['responses']['400']['content']['application/json']['error_code'],
  },
};

type BackendError = {
  error_code?: string;
};

const isBackendError = (error: Record<string, unknown>): error is BackendError =>
  !('error_code' in error) || typeof error.error_code === 'string' || error.error_code === undefined;

export const isApiError = (
  error: any,
): error is {
  response: Response;
  data: BackendError;
} => {
  return isRequestError(error) && isBackendError(error.data);
};

export const getApiErrorCode = (error: { response: Response; data: BackendError }) => error.data.error_code;
