import * as Dialog from '@radix-ui/react-dialog';
import { useControllableState } from '@radix-ui/react-use-controllable-state';
import React, { ComponentProps } from 'react';

import { ModalContext } from './modal.context';

interface ModalProps {
  onOpenChange?: ComponentProps<typeof Dialog.Root>['onOpenChange'];
  isOpen?: ComponentProps<typeof Dialog.Root>['open'];
  children: React.ReactNode;
}

export const Modal = ({ isOpen, onOpenChange, children }: ModalProps) => {
  const [_isOpen, setIsOpen] = useControllableState({
    defaultProp: false,
    prop: isOpen,
    onChange: onOpenChange,
  });

  return (
    <ModalContext.Provider
      value={{
        isOpen: _isOpen,
      }}
    >
      {/* Radix dialog performance issue - https://github.com/radix-ui/primitives/issues/1634 */}
      {/* Modal need to be set false -> overlay, body scroll lock captured in RemoveScroll from react-remove-scroll  */}
      {/* Custom overlay from react-remove-scroll */}
      {/* Test when radix-dialog update /react-remove-scroll which use react-remove-scroll-bar 2.3.6 */}
      {/* https://github.com/theKashey/react-remove-scroll-bar/commit/7278f9a523e7f007c328fb5b3b9910de63feb80a */}
      {/* If modal is set to false -> could not stack modals */}
      <Dialog.Root open={_isOpen} onOpenChange={setIsOpen} modal={false}>
        {children}
      </Dialog.Root>
    </ModalContext.Provider>
  );
};
