import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { Typography } from '@/components/_atoms/Typography';
import { Button } from '@/components/_molecules/Button';
import { schemas } from '@/network/client';

import { PostItemTime } from '../PostItemTime';

export const PostItemFull = (props: schemas['MainPagePost']) => {
  const { image, title, path, created_at, read_time, category_name } = props;

  return (
    <Link href={path}>
      <div className="flex flex-col overflow-hidden rounded-lg bg-grey-100 laptop:flex-row">
        <div className="order-2 flex w-full flex-col p-6 laptop:order-1 laptop:w-1/2 laptop:p-10">
          <Typography variant="body_s" bold color="grey-semi" className="mb-2">
            {category_name}
          </Typography>
          <Typography variant="body_xxl" bold>
            {title}
          </Typography>
          <div className="mb-8 mt-6 flex-1 laptop:mb-0">
            <PostItemTime createdAt={created_at} readTime={read_time} />
          </div>
          <div>
            <Button variant="primary" textId="blog.readMore" className="w-full laptop:w-auto" />
          </div>
        </div>
        <div className="relative order-1 h-[325px] w-full overflow-hidden laptop:order-2 laptop:w-1/2">
          {image && <Image src={image} alt={title} height={325} width={600} />}
        </div>
      </div>
    </Link>
  );
};
