import React from 'react';

import { Icon, Icons } from '@/components/_atoms/Icon';
import { schemas } from '@/network/client';
import { cn } from '@/utils/styles.utils';

interface Props {
  season: string;
  size?: number;
}

const seasonToIconMap: Record<schemas['SeasonEnum'], Icons> = {
  letnie: 'seasonSummer',
  zimowe: 'seasonWinter',
  całoroczne: 'seasonAll',
};

export const ProductSeasonIcon = (props: Props) => {
  const { season, size = 24 } = props;
  const typedSeason = season as schemas['SeasonEnum'];
  const icon = seasonToIconMap[typedSeason];

  if (season === 'całoroczne') {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          className="fill-blue-500"
          d="M22.9 13.9L20.8 13.3L22.1 12.2C22.5 11.8 22.6 11.2 22.2 10.8C21.8 10.4 21.2 10.3 20.8 10.7L18.5 12.7L13.9 11.5L17.2 8.20001L20.2 8.80001C20.7 8.90001 21.3 8.60001 21.4 8.00001C21.5 7.50001 21.2 6.90001 20.6 6.80001L19 6.40001L20.5 4.90001C20.8 4.60001 20.9 4.10001 20.6 3.70001L3.7 20.6C4.1 20.8 4.6 20.8 4.9 20.5L6.4 19L6.7 20.7C6.8 21.2 7.3 21.6 7.9 21.5C8.4 21.4 8.8 20.9 8.7 20.3L8.1 17.3L11.4 14L12.6 18.6L10.6 20.9C10.2 21.3 10.3 21.9 10.7 22.3C11.1 22.7 11.7 22.6 12.1 22.2L13.2 20.9L13.8 23C13.9 23.5 14.5 23.9 15 23.7C15.5 23.6 15.9 23 15.7 22.5L15.1 20.4L16.7 21C17.2 21.2 17.8 20.9 18 20.4C18.2 19.9 17.9 19.3 17.4 19.1L14.5 18.1L13.3 13.5L17.9 14.7L18.9 17.6C19.1 18.1 19.6 18.4 20.2 18.2C20.7 18 21 17.5 20.8 16.9L20.2 15.3L22.3 15.9C22.8 16 23.4 15.7 23.5 15.2C23.7 14.6 23.4 14 22.9 13.9Z"
        />
        <path
          className="fill-orange-500"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1 13H4C4 13 4 13 4.1 13C4.3 14.5 4.9 15.8 5.7 16.9L3.6 19C3.3 19.3 3.2 19.8 3.5 20.2L7.7 16C6.6 15 6 13.6 6 12C6 8.7 8.7 6 12 6C13.6 6 15 6.6 16.1 7.6L20.3 3.4C19.9 3.2 19.4 3.2 19.1 3.5L17 5.6C15.9 4.7 14.6 4.2 13.1 4C13.1 4 13.1 4 13.1 3.9V1C13.1 0.4 12.7 0 12.1 0C11.5 0 11.1 0.4 11.1 1V4C11.1 4 11.1 4 11.1 4.1C9.5 4.2 8.2 4.8 7.1 5.7L4.9 3.5C4.5 3.1 3.9 3.1 3.5 3.5C3.1 3.9 3.1 4.5 3.5 4.9L5.6 7C4.8 8.2 4.2 9.5 4.1 11C4.1 11 4.1 11 4 11H1C0.4 11 0 11.4 0 12C0 12.6 0.4 13 1 13Z"
        />
      </svg>
    );
  }

  return (
    <Icon
      className={cn({
        'fill-blue-500': typedSeason === 'zimowe',
        'fill-orange-500': typedSeason === 'letnie',
      })}
      name={icon}
      size={size}
    />
  );
};
