import { AnimatePresence, m } from 'framer-motion';
import { useTranslations } from 'next-intl';
import React, { useState } from 'react';

import { cn } from '@/utils/styles.utils';

import { Checkbox } from '../Checkbox';

interface Option {
  value: string;
  name: string;
  count?: number;
}
export interface CheckboxGroupProps {
  value: string[];
  onChange: (value: string[]) => void;
  options: Option[];
  spaces?: 's' | 'm';
  size?: 's' | 'm';
  direction?: 'row' | 'column';
  visibleItems?: number;
  renderChildren?: (option: Option) => React.ReactNode;
}

const getOptionValue = (value: string | null) => value || 'brak';

export const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
  const t = useTranslations();
  const {
    options,
    value,
    onChange,
    spaces = 's',
    visibleItems,
    size = 'm',
    direction = 'column',
    renderChildren,
  } = props;
  const [allVisible, setAllVisible] = useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    if (checked) {
      onChange([...value, name]);
    } else {
      onChange(value.filter((v) => v !== name));
    }
  };

  const visibleOptions = visibleItems ? options.slice(0, visibleItems) : options;
  const hiddenOptions = visibleItems ? options.slice(visibleItems) : [];
  const renderItem = (option: Option) => (
    <li className="flex" key={option.name}>
      <Checkbox
        name={option.value}
        size={size}
        disabled={option.count === 0}
        onChange={handleChange}
        label={option.name || 'brak'}
        count={option.count}
        checked={value.includes(getOptionValue(option.value))}
      >
        {renderChildren?.(option)}
      </Checkbox>
    </li>
  );
  return (
    <>
      <ul
        className={cn('flex', {
          'space-y-3': spaces === 'm',
          'flex-row space-x-4': direction === 'row',
          'flex-col space-y-2': direction === 'column',
        })}
      >
        {visibleOptions.map(renderItem)}
        <AnimatePresence initial={false}>
          {allVisible && (
            <m.div
              key="list"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <div
                className={cn('flex', {
                  'space-y-3': spaces === 'm',
                  'flex-row space-x-4': direction === 'row',
                  'flex-col space-y-2': direction === 'column',
                })}
              >
                {hiddenOptions.map(renderItem)}
              </div>
            </m.div>
          )}
        </AnimatePresence>
      </ul>
      {!!hiddenOptions.length && (
        <button
          className={
            'mt-[10px] py-[10px] text-body-m font-bold text-text-orange-dark transition-colors hover:text-text-orange'
          }
          onClick={() => setAllVisible((v) => !v)}
        >
          {t(allVisible ? 'checkboxGroup.showLess' : 'checkboxGroup.showMore')}
        </button>
      )}
    </>
  );
};
