import { ReactNode } from 'react';

import { Grid } from '@/components/_atoms/Grid';
import { Logo } from '@/components/_atoms/Logo';
import { Typography } from '@/components/_atoms/Typography';
import { EMAIL, PHONE } from '@/constants/contact.constants';
import settings from '@/static/settings.static.json';

import { FooterCertificate } from '../FooterCertificate';

const Email = ({ br }: { br?: boolean }) => (
  <Typography
    variant="body_m"
    color="white"
    id="footerInfo.contact.email"
    values={{
      email: EMAIL,
      link: (msg: ReactNode) => (
        <a href={`mailto:${EMAIL}`}>
          <Typography variant="body_m" color="grey-super-light" as="span">
            {msg}
          </Typography>
        </a>
      ),
      br: () => (br ? <br /> : ' '),
    }}
  />
);

const Phone = ({ br }: { br?: boolean }) => (
  <Typography
    variant="body_m"
    color="white"
    id="footerInfo.contact.phone"
    values={{
      phone: PHONE,
      link: (msg: ReactNode) => (
        <a href={`tel:${PHONE}`}>
          <Typography variant="body_m" color="grey-super-light" as="span">
            {msg}
          </Typography>
        </a>
      ),
      br: () => (br ? <br /> : ' '),
    }}
  />
);

const MondayToFriday = ({ br }: { br?: boolean }) => (
  <Typography
    variant="body_m"
    color="white"
    id="footerInfo.hours.mondayToFriday"
    className="leading-6"
    values={{
      value: (
        <Typography
          variant="body_m"
          color="grey-super-light"
          as="span"
          id="openingHours"
          values={{
            start: settings.monday_friday_start,
            end: settings.monday_friday_end,
          }}
        />
      ),
      br: () => (br ? <br /> : ' '),
    }}
  />
);

const Saturday = ({ br }: { br?: boolean }) => (
  <Typography
    variant="body_m"
    color="white"
    id="footerInfo.hours.saturday"
    className="leading-6"
    values={{
      value: (
        <Typography
          variant="body_m"
          color="grey-super-light"
          as="span"
          id="openingHours"
          values={{
            start: settings.saturday_start,
            end: settings.saturday_end,
          }}
        />
      ),
      br: () => (br ? <br /> : ' '),
    }}
  />
);

export const FooterInfo = () => {
  const isHotlineAvailableOnSaturday = !!settings.saturday_start && !!settings.saturday_end;
  return (
    <Grid className="mt-8 overflow-hidden">
      <Grid.Item mobile={12} laptop={6}>
        <div className="mb-8 w-[132px]">
          <Logo />
        </div>
        <div className="hidden laptop:block">
          <FooterCertificate />
        </div>
      </Grid.Item>
      <Grid.Item mobile={12} laptop={6}>
        <Grid className="mb-6 gap-6">
          <Grid.Item mobile={6} tablet={12} laptop={12} className="space-y-6">
            <Typography id="footerInfo.companyName" variant="body_l" bold color="white" />
            <Typography id="footerInfo.address" variant="body_m" color="grey-super-light" />
            <Grid>
              <Grid.Item mobile={12} tablet={6} laptop={12}>
                <div className="desktop:hidden">
                  <Phone br />
                </div>
                <div className="hidden desktop:block">
                  <Phone />
                </div>
              </Grid.Item>
              <Grid.Item mobile={12} tablet={6} laptop={12}>
                <div className="desktop:hidden">
                  <Email br />
                </div>
                <div className="hidden desktop:block">
                  <Email />
                </div>
              </Grid.Item>
            </Grid>
          </Grid.Item>
          <Grid.Item mobile={6} tablet={12} className="space-y-6">
            <Typography id="footerInfo.hotline" variant="body_l" bold color="white" />
            <Grid>
              <Grid.Item mobile={12} tablet={6} laptop={12}>
                <div className="desktop:hidden">
                  <MondayToFriday br />
                </div>
                <div className="hidden desktop:block">
                  <MondayToFriday />
                </div>
              </Grid.Item>
              {isHotlineAvailableOnSaturday && (
                <Grid.Item mobile={12} tablet={6} laptop={12}>
                  <div className="desktop:hidden">
                    <Saturday br />
                  </div>
                  <div className="hidden desktop:block">
                    <Saturday />
                  </div>
                </Grid.Item>
              )}
            </Grid>
          </Grid.Item>
        </Grid>
        <div className="hidden tablet:max-laptop:block">
          <FooterCertificate />
        </div>
      </Grid.Item>
    </Grid>
  );
};
