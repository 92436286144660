import { useState } from 'react';

import { MenuCategory } from '@/components/Header/Header.types';
import menu from '@/static/menu.static.json';
import { cn } from '@/utils/styles.utils';

import { HeaderMobileAccordion } from '../HeaderMobileAccordion';
import { HeaderMobileMenuList } from '../HeaderMobileMenuList';

interface Props {
  close: () => void;
}

const rowClassName = cn('m-6 mb-8 flex');

export const HeaderMobileCategories = (props: Props) => {
  const { close } = props;
  const [activeCategory, setActiveCategory] = useState<MenuCategory>();

  const { tyreMenu, winterTyreMenu, summerTyreMenu, allSeasonTyreMenu } = menu;
  const onClick = (category: MenuCategory) => {
    if (category === activeCategory) {
      setActiveCategory(undefined);
    } else {
      setActiveCategory(category);
    }
  };
  return (
    <>
      <HeaderMobileAccordion
        titleId="mobileMenu.category.tyres"
        isActive={activeCategory === 'TYRES'}
        onClick={() => onClick('TYRES')}
      >
        <div>
          <div className={rowClassName}>
            <div className="flex-1">
              <HeaderMobileMenuList {...tyreMenu.seasons} closeMenu={close} />
            </div>
            <div className="flex-1">
              <HeaderMobileMenuList {...tyreMenu.tyre_types} closeMenu={close} />
            </div>
          </div>
          <div className={rowClassName}>
            <div className="flex-1">
              <HeaderMobileMenuList {...tyreMenu.producers} closeMenu={close} columns />
            </div>
          </div>
          <div className={rowClassName}>
            <div className="flex-1">
              <HeaderMobileMenuList {...tyreMenu.popular_sizes} closeMenu={close} columns />
            </div>
          </div>
          <div className={rowClassName}>
            <div className="flex-1">
              <HeaderMobileMenuList {...tyreMenu.popular_models} closeMenu={close} />
            </div>
          </div>
        </div>
      </HeaderMobileAccordion>
      <HeaderMobileAccordion
        titleId="mobileMenu.category.winterTyres"
        isActive={activeCategory === 'WINTER_TYRES'}
        onClick={() => onClick('WINTER_TYRES')}
      >
        <div>
          <div className={rowClassName}>
            <div className="flex-1">
              <HeaderMobileMenuList {...winterTyreMenu.winter_producers} closeMenu={close} />
            </div>
          </div>
          <div className={rowClassName}>
            <div className="flex-1">
              <HeaderMobileMenuList {...winterTyreMenu.winter_sizes} closeMenu={close} />
            </div>
          </div>
          <div className={rowClassName}>
            <div className="flex-1">
              <HeaderMobileMenuList {...winterTyreMenu.winter_rims} closeMenu={close} columns />
            </div>
          </div>
        </div>
      </HeaderMobileAccordion>
      <HeaderMobileAccordion
        titleId="mobileMenu.category.allSeasonTyres"
        isActive={activeCategory === 'ALL_SEASON_TYRES'}
        onClick={() => onClick('ALL_SEASON_TYRES')}
      >
        <div>
          <div className={rowClassName}>
            <div className="flex-1">
              <HeaderMobileMenuList {...allSeasonTyreMenu.all_season_producers} closeMenu={close} />
            </div>
          </div>
          <div className={rowClassName}>
            <div className="flex-1">
              <HeaderMobileMenuList {...allSeasonTyreMenu.all_season_sizes} closeMenu={close} />
            </div>
          </div>
          <div className={rowClassName}>
            <div className="flex-1">
              <HeaderMobileMenuList {...allSeasonTyreMenu.all_season_rims} closeMenu={close} columns />
            </div>
          </div>
        </div>
      </HeaderMobileAccordion>
      <HeaderMobileAccordion
        titleId="mobileMenu.category.summerTyres"
        isActive={activeCategory === 'SUMMER_TYRES'}
        onClick={() => onClick('SUMMER_TYRES')}
      >
        <div>
          <div className={rowClassName}>
            <div className="flex-1">
              <HeaderMobileMenuList {...summerTyreMenu.summer_producers} closeMenu={close} />
            </div>
          </div>
          <div className={rowClassName}>
            <div className="flex-1">
              <HeaderMobileMenuList {...summerTyreMenu.summer_sizes} closeMenu={close} />
            </div>
          </div>
          <div className={rowClassName}>
            <div className="flex-1">
              <HeaderMobileMenuList {...summerTyreMenu.summer_rims} closeMenu={close} columns />
            </div>
          </div>
        </div>
      </HeaderMobileAccordion>
    </>
  );
};
