import createClient, { FetchResponse } from 'openapi-fetch';

import { BASE_URL } from '@/constants/config.constants';

import type { components, paths } from './openapi';

export type schemas = components['schemas'];

export const client = createClient<paths>({
  baseUrl: BASE_URL,
  querySerializer: {
    array: {
      style: 'form',
      explode: false,
    },
  },
});

export const serverClient = createClient<paths>({
  baseUrl: BASE_URL,
  querySerializer: {
    array: {
      style: 'form',
      explode: false,
    },
  },
});

export const authClient = createClient({
  baseUrl: '/api',
  querySerializer: {
    array: {
      style: 'form',
      explode: false,
    },
  },
});

export const unwrapResponse = <T extends Record<string | number, any>, Options, Media extends `${string}/${string}`>(
  fetchResponse: FetchResponse<T, Options, Media>,
) => {
  const { response, data, error } = fetchResponse;

  if (response.status >= 400) {
    return Promise.reject({
      data: error,
      response,
    });
  }

  return data as Exclude<FetchResponse<T, Options, Media>['data'], undefined>;
};

function stringifyFormItem(formItem: unknown) {
  if (typeof formItem === 'object' && formItem !== null) {
    return JSON.stringify(formItem);
  } else {
    return `${formItem}`;
  }
}

export const formDataBodySerializer = <T extends Record<string, unknown>>(body: T) => {
  const fd = new FormData();

  for (const name in body) {
    const property = body[name];
    const propertyContent: unknown[] = property instanceof Array ? property : [property];
    for (const formItem of propertyContent) {
      const isFileType = formItem instanceof Blob || formItem instanceof File;
      fd.append(name, isFileType ? formItem : stringifyFormItem(formItem));
    }
  }
  return fd;
};

export const isRequestError = (
  error: unknown,
): error is {
  data: Record<string, unknown>;
  response: Response;
} => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'response' in error &&
    error.response instanceof Response &&
    'data' in error &&
    typeof error.data === 'object' &&
    error.data !== null
  );
};
