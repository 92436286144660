import { Typography } from '@/components/_atoms/Typography';
import { Button } from '@/components/_molecules/Button';
import { schemas } from '@/network/client';

type Props = schemas['CarSizes'] & {
  index: number;
  onClick: (id: string, data: schemas['CarSizeDetail']) => void;
};

export const SelectCarSizeItem = (props: Props) => {
  const { front, rear, onClick, id } = props;

  const onClickWrapper = () => {
    onClick(id, front);
  };
  return (
    <div className="flex items-center justify-between rounded bg-white px-4 py-3">
      <div className="flex">
        <div className="mr-4 laptop:mr-8">
          <Typography id="selectCarSizeItem.rim" variant="body_s" color="grey-standard" className="mb-1" />
          <Typography variant="body_m" bold>
            {rear ? `${front.rim}”/${rear.rim}”` : `${front.rim}”`}
          </Typography>
        </div>
        <div className="mr-2 flex-1 laptop:mr-4">
          <Typography id="selectCarSizeItem.size" variant="body_s" color="grey-standard" className="mb-1" />
          {rear ? (
            <Typography
              variant="body_m"
              bold
              id="selectCarSizeItem.frontSize"
              values={{ front: front.size, rear: rear.size }}
            />
          ) : (
            <Typography variant="body_m" bold>
              {front.size}
            </Typography>
          )}
        </div>
      </div>
      <Button onClick={onClickWrapper} variant="subtle" size="s" textId="selectCarSizeItem.selectButton" />
    </div>
  );
};
