import React from 'react';

import { ComplexIconsName } from '@/types/complexIcons';

interface IconProps {
  size?: number;
  name: ComplexIconsName;
  className?: string;
}

export const ComplexIcon = (props: IconProps) => {
  const { size = 32, name, ...restProps } = props;
  return (
    <svg {...restProps} width={size} height={size}>
      <use href={`/complexIcons-sprite.svg#${name}`} />
    </svg>
  );
};
