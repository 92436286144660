import { useCallback, useEffect, useState } from 'react';

import { schemas } from '@/network/client';
import sizesStaticData from '@/static/sizes.static.json';
import { TyreSize } from '@/types/TyreSearch.types';

export function useTyreSizeFilters(value: string | null, data: schemas['Sizes'] = sizesStaticData) {
  const [_width = '', _profile = '', _rim = ''] = value ? data.sizes[value] || [] : [];

  const [size, setSize] = useState<TyreSize>({
    width: _width,
    profile: _profile,
    rim: _rim,
  });
  const { width, profile } = size;
  const { widths, profiles_by_width: profilesByWidth, rims_by_width_and_profile: rimsByWidthAndProfile } = data;

  const profiles = profilesByWidth[width] || [];
  const rims = rimsByWidthAndProfile[`${width}/${profile}`] || [];

  const changeTyreSize = useCallback((value: string, field: keyof TyreSize) => {
    setSize((prevSize) => {
      if (field === 'width') {
        return {
          ...prevSize,
          width: value,
          profile: '',
          rim: '',
        };
      }
      if (field === 'profile') {
        return {
          ...prevSize,
          profile: value,
          rim: '',
        };
      }
      if (field === 'rim') {
        return {
          ...prevSize,
          rim: value,
        };
      }
      return prevSize;
    });
  }, []);

  useEffect(() => {
    setSize({
      width: _width,
      profile: _profile,
      rim: _rim,
    });
  }, [_width, _profile, _rim]);

  return {
    changeTyreSize,
    size,
    widths,
    profiles,
    rims,
  };
}
