import React from 'react';

import { Typography } from '@/components/_atoms/Typography';
import { PostsCarousel } from '@/components/_molecules/PostsCarousel';
import { schemas } from '@/network/client';

interface Props {
  data: schemas['MainPagePost'][];
}

export const PostsSection = (props: Props) => {
  const { data } = props;
  return (
    <>
      <Typography
        as="p"
        bold
        id="homeScreen.posts.header"
        className={'mb-6 text-header-l laptop:mb-10 laptop:text-header-xl'}
      />
      <PostsCarousel posts={data} />
    </>
  );
};
