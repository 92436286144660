import { useMutation, useQueryClient } from '@tanstack/react-query';

import { setCheckoutToken } from '@/logic/checkout/checkoutToken';
import { QueryKeys } from '@/network/query.types';

import { ApiErrorCodes } from '../api.utils';
import { client, schemas, unwrapResponse } from '../client';

export const useCheckoutCreateMutation = () => {
  return useMutation({
    mutationFn: (data: { checkoutLine: schemas['CreateCheckout']; token?: string }) =>
      client
        .POST('/api/v1/checkout', {
          body: data.checkoutLine,
          params: { query: data.token ? { token: data.token } : {} },
        })
        .then(unwrapResponse),
    onSuccess: (checkout) => {
      setCheckoutToken(checkout.token);
    },
    meta: {
      captureException: true,
      excludeErrorCodes: Object.values(ApiErrorCodes.createUpdateCheckout),
    },
  });
};

export const useChangeCheckoutQuantityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { checkoutLine: schemas['CreateCheckout']; token?: string }) =>
      client
        .POST('/api/v1/checkout', {
          body: data.checkoutLine,
          params: { query: data.token ? { token: data.token } : {} },
        })
        .then(unwrapResponse),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.checkout],
      });
    },
    meta: {
      captureException: true,
      excludeErrorCodes: Object.values(ApiErrorCodes.createUpdateCheckout),
    },
  });
};

export function useSearchCompanyByVatNumber() {
  return useMutation({
    mutationFn: (nip: string) =>
      client
        .GET('/api/v1/core/regon_api/search_by_nip/{nip}', {
          params: {
            path: {
              nip,
            },
          },
        })
        .then(unwrapResponse),
    meta: {
      captureException: true,
    },
  });
}
