import useEmblaCarousel from 'embla-carousel-react';

import { CarouselDots } from '@/components/_molecules/Carousel';
import { schemas } from '@/network/client';
import { chunkArray } from '@/utils/array.utils';
import { cn } from '@/utils/styles.utils';

import { ProducerItem } from './ProducerItem';

interface Props {
  producers: schemas['MainPageProducer'][];
}

export const ProducersSectionCarousel = (props: Props) => {
  const { producers } = props;
  const chunks = chunkArray(producers, 3);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    duration: 20,
  });

  return (
    <>
      <div className="relative">
        <div className="overflow-hidden" ref={emblaRef}>
          <div
            className={cn([
              'flex',
              {
                '-ml-2 [&>*]:flex-[0_0_50%] [&>*]:pl-2': true,
                'tablet:-ml-3 tablet:[&>*]:flex-[0_0_calc(100%/3)] tablet:[&>*]:pl-3': true,
                'laptop:-ml-6 laptop:[&>*]:flex-[0_0_20%] laptop:[&>*]:pl-6': true,
              },
            ])}
          >
            {chunks.map((item) => (
              <div key={item.map((v) => v.path).join('')}>
                <ProducerItem data={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-6">
        <CarouselDots emblaApi={emblaApi} />
      </div>
    </>
  );
};
