import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react';

import {
  useCarSearchEnginesQuery,
  useCarSearchGenerationsQuery,
  useCarSearchModelsQuery,
  useCarSearchProducers,
} from '@/network/_queries/carSearch.queries';

import { SelectCarValue } from './selectCar.types';

export const useSelectCar = (
  value: SelectCarValue | null,
  setValue: Dispatch<SetStateAction<SelectCarValue | null>>,
) => {
  const mounted = useRef(false);
  // const [value, setValue] = useState<SelectCarValue>(_value || {});
  const { producer = '', model = '', year = '', generation = '' } = value || {};
  const producersQuery = useCarSearchProducers();
  const modelsQuery = useCarSearchModelsQuery(producer);
  const generationsQuery = useCarSearchGenerationsQuery(producer, model, year);
  const enginesQuery = useCarSearchEnginesQuery(producer, model, year, generation);

  const onChange = useCallback((value: string, field: keyof SelectCarValue) => {
    setValue((prevSize) => {
      if (field === 'producer') {
        return {
          producer: value,
          model: '',
          year: '',
          generation: '',
          engine: '',
        };
      }
      if (field === 'model') {
        return {
          ...prevSize,
          model: value,
          year: '',
          generation: '',
          engine: '',
        };
      }
      if (field === 'year') {
        return {
          ...prevSize,
          year: value,
          generation: '',
          engine: '',
        };
      }

      if (field === 'generation') {
        return {
          ...prevSize,
          generation: value,
          engine: '',
        };
      }

      if (field === 'engine') {
        return {
          ...prevSize,
          engine: value,
        };
      }
      return prevSize;
    });
  }, []);

  const selectCarIsProcessing = [producersQuery, modelsQuery, generationsQuery, enginesQuery].some(
    (query) => query.fetchStatus !== 'idle' && (query.isLoading || query.isFetching),
  );

  useEffect(() => {
    if (modelsQuery?.data?.length === 1 && mounted.current && modelsQuery.data[0]) {
      onChange(modelsQuery.data[0].slug, 'model');
    }
  }, [modelsQuery.data]);

  useEffect(() => {
    if (generationsQuery?.data?.length === 1 && mounted.current && generationsQuery.data[0]) {
      onChange(generationsQuery.data[0].generation_slug, 'generation');
    }
  }, [generationsQuery.data]);

  useEffect(() => {
    if (enginesQuery?.data?.length === 1 && mounted.current && enginesQuery.data[0]) {
      onChange(enginesQuery.data[0].modification_slug, 'engine');
    }
  }, [enginesQuery.data]);

  useEffect(() => {
    mounted.current = true;
  }, []);

  return {
    producers: producersQuery.data,
    models: modelsQuery.data,
    generations: generationsQuery.data,
    engines: enginesQuery.data,
    selectCarOnChange: onChange,
    selectCarIsProcessing,
  };
};
