import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { Typography } from '@/components/_atoms/Typography';
import { schemas } from '@/network/client';

import { PostItemTime } from './_components/PostItemTime/PostItemTime';

export const PostItem = (props: schemas['MainPagePost']) => {
  const { image, title, path, created_at, read_time, category_name } = props;

  return (
    <Link href={path} className="flex h-[380px] flex-col overflow-hidden rounded-lg bg-grey-100">
      {image && <Image src={image} alt={title} width={500} height={210} className="h-[210px]" />}
      <div className="flex flex-1 flex-col justify-between p-6">
        <div>
          <Typography variant="body_s" bold color="grey-semi" className="mb-2">
            {category_name}
          </Typography>
          <Typography variant="body_l" bold>
            {title}
          </Typography>
        </div>
        <PostItemTime createdAt={created_at} readTime={read_time} />
      </div>
    </Link>
  );
};
