import { useScroll, useMotionValueEvent } from 'framer-motion';
import React, { startTransition, useState } from 'react';

import { Container } from '@/components/_atoms/Container';
import { DropdownWrapper } from '@/components/_molecules/DropdownWrapper';
import { useBySize } from '@/hooks/useBySize';
import { useScrollUp } from '@/hooks/useScrollUp';
import { useHeaderCtx } from '@/providers/header.provider';
import { cn } from '@/utils/styles.utils';

import { HeaderLogo } from '../../../_molecules/HeaderLogo';
import { MenuCategory } from '../../Header.types';
import { HeaderAccount } from '../HeaderAccount';
import { HeaderBasketElement } from '../HeaderBasketElement';
import { HeaderBottomNav } from '../HeaderBottomNav';
import { HeaderCompare } from '../HeaderCompare';
import { HeaderLastSeen } from '../HeaderLastSeen';
import { HeaderMenu } from '../HeaderMenu';
import { HeaderMobile } from '../HeaderMobile';

import { HEADER_HEIGHT, HEADER_HIDE_OFFSET } from './HeaderBottom.constants';

export const HeaderBottom = () => {
  const [hidden, setHidden] = useState(false);
  const [activeCategory, setActiveCategory] = useState<MenuCategory>();
  const openMenu = (category: MenuCategory) => setActiveCategory(category);
  const closeMenu = () => setActiveCategory(undefined);
  const isMenuOpen = !!activeCategory;
  const headerCtx = useHeaderCtx();

  const { updateScrollPosition } = useScrollUp();

  const isMobile = useBySize({
    mobile: true,
    laptop: false,
  });
  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, 'change', (current) => {
    const tabsTop = headerCtx.tabsRef.current?.getBoundingClientRect().top;
    const tabsAreSticky = tabsTop !== undefined && tabsTop <= HEADER_HEIGHT;
    if (headerCtx.tabsRef.current) {
      headerCtx.setTabsAreSticky(tabsAreSticky);
    }

    const { isScrolled } = updateScrollPosition(current);

    if ((!isScrolled && current > HEADER_HIDE_OFFSET) || tabsAreSticky) {
      startTransition(() => setHidden(true));
    } else {
      startTransition(() => setHidden(false));
    }
  });

  return (
    <div
      className={cn(
        'sticky top-0 z-[var(--z-index-header)] h-[var(--header-height)] translate-y-0 bg-white shadow-bottom transition-transform duration-300',
        {
          '-translate-y-full': hidden && isMobile,
        },
      )}
    >
      <Container>
        <div className="laptop:hidden">
          <HeaderMobile />
        </div>
        <div className="hidden laptop:block">
          <div className="relative" onMouseLeave={closeMenu}>
            <div className="flex h-[var(--header-height)]">
              <div
                className={cn(
                  'relative flex items-center',
                  'before:absolute before:bottom-0 before:right-0 before:top-0 before:z-[-1] before:w-[2000px] before:bg-grey-50',
                )}
              >
                <div onMouseEnter={closeMenu}>
                  <HeaderLogo />
                </div>
                <div className="flex-1">
                  <HeaderBottomNav openMenu={openMenu} closeMenu={closeMenu} activeCategory={activeCategory} />
                </div>
              </div>
              <div className="flex flex-1 items-center justify-end" onMouseEnter={closeMenu}>
                <div className="flex h-full">
                  <HeaderLastSeen />
                  <HeaderCompare />
                  <HeaderAccount />
                </div>
                <HeaderBasketElement device="desktop" />
              </div>
            </div>
            <DropdownWrapper isOpen={isMenuOpen} className="absolute top-[calc(100%-4px)] w-full">
              {activeCategory && <HeaderMenu closeMenu={closeMenu} activeCategory={activeCategory} />}
            </DropdownWrapper>
          </div>
        </div>
      </Container>
    </div>
  );
};
