import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from '@/network/query.types';

import { client, unwrapResponse } from '../client';

export function useCarSearchProducers() {
  return useQuery({
    queryKey: [QueryKeys.carSearchProducers],
    queryFn: () => client.GET('/api/v1/car_search_bar/makes').then(unwrapResponse),
    staleTime: Infinity,
  });
}

export function useCarSearchModelsQuery(producer: string) {
  return useQuery({
    queryKey: [QueryKeys.carSearchModels, producer],
    queryFn: () =>
      client
        .GET('/api/v1/car_search_bar/makes/{make_slug}/models', {
          params: {
            path: {
              make_slug: producer,
            },
          },
        })
        .then(unwrapResponse),
    staleTime: Infinity,
    enabled: !!producer,
  });
}

export function useCarSearchGenerationsQuery(producer: string, model: string, year: string) {
  return useQuery({
    queryKey: [
      QueryKeys.carSearchGenerations,
      {
        producer,
        model,
        year,
      },
    ],
    queryFn: () =>
      client
        .GET('/api/v1/car_search_bar/makes/{make_slug}/models/{model_slug}/{year}/generations', {
          params: {
            path: {
              make_slug: producer,
              model_slug: model,
              year,
            },
          },
        })
        .then(unwrapResponse),
    staleTime: Infinity,
    enabled: !!producer && !!model && !!year,
  });
}

export function useCarSearchEnginesQuery(producer: string, model: string, year: string, generation: string) {
  return useQuery({
    queryKey: [
      QueryKeys.carSearchEngines,
      {
        producer,
        model,
        year,
        generation,
      },
    ],
    queryFn: () =>
      client
        .GET(
          '/api/v1/car_search_bar/makes/{make_slug}/models/{model_slug}/{year}/generations/{generation_slug}/engines',
          {
            params: {
              path: {
                generation_slug: generation,
                make_slug: producer,
                model_slug: model,
                year,
              },
            },
          },
        )
        .then(unwrapResponse),
    staleTime: Infinity,
    enabled: !!producer && !!model && !!year && !!generation,
  });
}

export function useCarSearchSizesQuery(
  producer: string,
  model: string,
  year: string,
  generation: string,
  engine: string,
) {
  return useQuery({
    queryKey: [
      QueryKeys.carSearchSizes,
      {
        producer,
        model,
        year,
        generation,
        engine,
      },
    ],
    queryFn: () =>
      client
        .GET(
          '/api/v1/car_search_bar/makes/{make_slug}/models/{model_slug}/{year}/generations/{generation_slug}/engines/{modification_slug}/get_sizes',
          {
            params: {
              path: {
                generation_slug: generation,
                make_slug: producer,
                model_slug: model,
                modification_slug: engine,
                year,
              },
            },
          },
        )
        .then(unwrapResponse),
    staleTime: Infinity,
    enabled: !!producer && !!model && !!year && !!generation && !!engine,
  });
}
