import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Button } from '@/components/_molecules/Button';
import { Info } from '@/components/_molecules/Info';
import { ModalCard } from '@/components/_molecules/ModalCard';
import { CompareTyreProduct, useCompareTyresStore } from '@/logic/compareTyres';

import { ProductSimpleListItem } from '../../../../_molecules/ProductSimpleListItem';
import { ProductCompareSelect } from '../ProductCompareSelect';

interface Props {
  close: () => void;
  data: CompareTyreProduct;
}

export const ProductCompareModal = (props: Props) => {
  const t = useTranslations();
  const { close, data } = props;
  const { compareTyres, replaceTyreToCompare } = useCompareTyresStore();
  const [selected, setSelected] = useState<string>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSelect = () => {
    if (selected) {
      replaceTyreToCompare(selected, data);
      close();
    }
  };
  return (
    <ModalCard close={close} title={t('compareTyres.selectModal.title')}>
      <div className="border-b border-grey-300 pb-6">
        <ProductSimpleListItem {...data} />
      </div>
      <div className="pt-6">
        <Info />
      </div>
      <div className="pb-8 pt-4">
        <ProductCompareSelect options={compareTyres} value={selected} onChange={setSelected} />
      </div>
      <Button
        onClick={onSelect}
        text={t('compareTyres.selectModal.button')}
        className="w-full"
        variant="primary"
        disabled={!selected}
      />
    </ModalCard>
  );
};
