import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import React from 'react';

import { CarouselDots } from '@/components/_molecules/Carousel';
import { CarouselBannersPagination } from '@/components/_molecules/Carousel/CarouselBannersPagination';
import { schemas } from '@/network/client';

import { Banner } from './components/Banner';

interface Props {
  data: schemas['MainPageBanner'][];
}

export const BannersSection = (props: Props) => {
  const { data } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: 'start',
      duration: 20,
    },
    [
      Autoplay({
        playOnInit: true,
        delay: 4000,
        stopOnInteraction: false,
        stopOnMouseEnter: true,
      }),
    ],
  );

  if (!data.length) {
    return null;
  }

  return (
    <>
      <div className="mx-[calc(50%-50vw)] w-screen">
        <div className="relative">
          <div className="overflow-hidden" ref={emblaRef}>
            <div className="flex">
              {data.map((item, index) => (
                <div key={item.path} className="flex-[0_0_100%]">
                  <Banner key={item.path} index={index} {...item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="my-4 laptop:hidden">
          <CarouselDots emblaApi={emblaApi} />
        </div>
        <div className="hidden laptop:block">
          <CarouselBannersPagination emblaApi={emblaApi} data={data} />
        </div>
      </div>
    </>
  );
};
