import { useQuery } from '@tanstack/react-query';

import { AuthApi } from '../authApi';
import { QueryKeys } from '../query.types';

export function useSessionQuery() {
  return useQuery({
    queryKey: [QueryKeys.session],
    queryFn: AuthApi.getSession,
    refetchOnWindowFocus: true,
    staleTime: 5000,
  });
}
