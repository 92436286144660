import { EmblaCarouselType } from 'embla-carousel';

import { Icon } from '@/components/_atoms/Icon';
import { cn } from '@/utils/styles.utils';

import { useCarouselNavigation } from './useCarouselNavigation';

interface Props {
  emblaApi: EmblaCarouselType | undefined;
}

export const CarouselNavigation = (props: Props) => {
  const { emblaApi } = props;

  const { onNextButtonClick, onPrevButtonClick } = useCarouselNavigation(emblaApi);

  const navButtonClassName = cn(
    'absolute top-1/2 z-[1] -mt-3 hidden size-6 cursor-pointer transition-opacity laptop:block',
  );
  const navIconClassName = cn('fill-grey-600 transition-colors group-hover:fill-grey-1000');
  return (
    <>
      <button className={cn(navButtonClassName, 'left-0 laptop:-left-8 desktop:-left-12')} onClick={onPrevButtonClick}>
        <Icon name="chevronLeft" className={navIconClassName} />
      </button>
      <button
        className={cn(navButtonClassName, 'right-0 laptop:-right-8 desktop:-right-12')}
        onClick={onNextButtonClick}
      >
        <Icon name="chevronRight" className={navIconClassName} />
      </button>
    </>
  );
};
