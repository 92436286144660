import { dehydrate } from '@tanstack/react-query';
import { GetServerSideProps } from 'next';

import { getSelectCarCookie } from '@/logic/selectCar/selectCar.utils';
import { getSelectedTyreCookie } from '@/logic/selectedTyreConfig/selectedTyre.utils';
import { fetchMainPageQueryOptions } from '@/network/_queries/mainPage.queries';
import { createQueryClient } from '@/network/query.config';
import { HomeScreen } from '@/screens/Home/HomeScreen';
import { getMessages } from '@/utils/messages.utils';

export default HomeScreen;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const queryClient = createQueryClient();
  const selectedCar = getSelectCarCookie(context);
  const selectedTyre = getSelectedTyreCookie(context);

  try {
    await queryClient.fetchQuery(fetchMainPageQueryOptions());
    return {
      props: {
        messages: await getMessages(),
        dehydratedState: dehydrate(queryClient),
        initialZustandState: {
          selectedCar,
          selectedTyre,
        },
      },
    };
  } catch {
    return {
      notFound: true,
    };
  }
};
