import * as Accordion from '@radix-ui/react-accordion';
import { m, AnimatePresence } from 'framer-motion';
import { useState } from 'react';

import { schemas } from '@/network/client';
import { cn } from '@/utils/styles.utils';

import { Icon } from '../_atoms/Icon';
import { Typography } from '../_atoms/Typography';

interface Props {
  data: schemas['FAQ'][];
  questionTag?: 'h4' | 'p' | 'span';
}

export const Faq = (props: Props) => {
  const { data, questionTag = 'span' } = props;
  const [selected, setSelected] = useState<Array<string>>([]);

  return (
    <Accordion.Root type="multiple" value={selected} onValueChange={setSelected} className="divide-y divide-grey-400">
      {data.map((item) => {
        const open = selected.includes(item.question);
        return (
          <Accordion.Item key={item.question} value={item.question} className="block">
            <Accordion.Trigger
              className={cn(
                'flex min-h-[72px] w-full items-center justify-between rounded-lg bg-transparent p-6 text-left transition-colors hover:bg-grey-200',
                {
                  'bg-grey-200': open,
                },
              )}
            >
              <Typography as={questionTag} variant="body_l" bold>
                {item.question}
              </Typography>
              <m.span
                className="ml-2"
                animate={open ? 'open' : 'closed'}
                variants={{
                  open: {
                    rotate: '180deg',
                  },
                  closed: {
                    rotate: '0deg',
                  },
                }}
              >
                <Icon
                  name="chevronDown"
                  size={24}
                  className={cn('fill-grey-600 transition-colors', {
                    'fill-grey-800': open,
                  })}
                />
              </m.span>
            </Accordion.Trigger>
            <Accordion.Content forceMount>
              <AnimatePresence initial={false}>
                {open && (
                  <m.div
                    key="list"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: 'auto' },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <div
                      className="pb-6 pl-6 pt-4 text-body-m text-text-grey-standard laptop:text-body-l"
                      dangerouslySetInnerHTML={{
                        __html: item.answer,
                      }}
                    />
                  </m.div>
                )}
              </AnimatePresence>
            </Accordion.Content>
          </Accordion.Item>
        );
      })}
    </Accordion.Root>
  );
};
