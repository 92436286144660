import Link from 'next/link';
import React from 'react';

import { Typography } from '@/components/_atoms/Typography';
import menuStaticData from '@/static/menu.static.json';
import { exhaustiveCheck } from '@/types/common.types';
import { MessagesKey } from '@/types/translations.types';
import { cn } from '@/utils/styles.utils';

import { MenuCategory } from '../../Header.types';

interface Props {
  openMenu: (category: MenuCategory) => void;
  closeMenu: () => void;
  activeCategory?: MenuCategory;
}

interface ItemProps {
  openMenu: (category: MenuCategory) => void;
  closeMenu: () => void;
  activeCategory?: MenuCategory;
  category: MenuCategory;
  labelId: MessagesKey;
}

const getLinkByCategory = (category: MenuCategory) => {
  if (category === 'TYRES') {
    return menuStaticData.tyreMenu.menu_path;
  }
  if (category === 'SUMMER_TYRES') {
    return menuStaticData.summerTyreMenu.menu_path;
  }
  if (category === 'WINTER_TYRES') {
    return menuStaticData.winterTyreMenu.menu_path;
  }
  if (category === 'ALL_SEASON_TYRES') {
    return menuStaticData.allSeasonTyreMenu.menu_path;
  }

  return exhaustiveCheck(category);
};

export const HeaderBottomNavItem = (itemProps: ItemProps) => {
  const { activeCategory, category, openMenu, labelId, closeMenu } = itemProps;
  return (
    <Link
      className={cn(
        'inline-flex h-[var(--header-height)] items-center justify-center px-3 transition-colors duration-300',
        'hover:bg-grey-200',
        {
          'bg-grey-200': activeCategory === category,
        },
      )}
      href={getLinkByCategory(category) || '/'}
      onClick={() => closeMenu()}
      onMouseEnter={() => openMenu(category)}
    >
      <Typography variant="body_l" bold id={labelId} />
    </Link>
  );
};

export const HeaderBottomNav = (props: Props) => {
  const { openMenu, activeCategory, closeMenu } = props;
  return (
    <nav className="w-full">
      <div className="ml-3 flex">
        <HeaderBottomNavItem
          category="TYRES"
          activeCategory={activeCategory}
          openMenu={openMenu}
          closeMenu={closeMenu}
          labelId="header.category.tyres"
        />
        <HeaderBottomNavItem
          category="WINTER_TYRES"
          activeCategory={activeCategory}
          openMenu={openMenu}
          closeMenu={closeMenu}
          labelId="header.category.winterTyres"
        />
        <HeaderBottomNavItem
          category="ALL_SEASON_TYRES"
          activeCategory={activeCategory}
          openMenu={openMenu}
          closeMenu={closeMenu}
          labelId="header.category.allSeasonTyres"
        />
        <HeaderBottomNavItem
          category="SUMMER_TYRES"
          activeCategory={activeCategory}
          openMenu={openMenu}
          closeMenu={closeMenu}
          labelId="header.category.summerTyres"
        />
      </div>
    </nav>
  );
};
