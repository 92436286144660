import { useTranslations } from 'next-intl';
import React, { startTransition, useState } from 'react';

import { useCar } from '@/logic/selectCar';
import { useSelectedTyre } from '@/logic/selectedTyreConfig';
import { cn } from '@/utils/styles.utils';

import { Typography } from '../_atoms/Typography';
import { Help } from '../_molecules/Help';
import { RadioGroup } from '../_molecules/RadioGroup';

import mainSearchTyre from './assets/main-search-tyre.webp';
import { SearchByCar } from './components/SearchByCar';
import { SearchBySize } from './components/SearchBySize';

interface Props {
  header: string;
  main?: boolean;
  query?: Record<string, unknown>;
  smallSelect?: boolean;
}

export const MainSearch = (props: Props) => {
  const { header, main, query, smallSelect } = props;
  const { selectedCar } = useCar();
  const selectedTyre = useSelectedTyre();

  const t = useTranslations();

  const [searchType, setSearchType] = useState<'size' | 'car'>(selectedCar ? 'car' : 'size');
  const onChange = (newType: 'size' | 'car') => {
    startTransition(() => setSearchType(newType));
  };
  return (
    <div>
      <div className="relative pb-8">
        <Typography
          as={main ? 'p' : 'h2'}
          bold
          className={cn('text-header-l laptop:text-header-xl', {
            'text-text-xl laptop:text-header-xxl': !!main,
          })}
        >
          {header}
        </Typography>
        <div className="flex items-center justify-between pt-4 laptop:mr-[236px]">
          <RadioGroup
            size="s"
            value={searchType}
            onChange={onChange}
            options={[
              {
                value: 'size',
                label: t('mainSearch.searchType.size'),
              },
              {
                value: 'car',
                label: t('mainSearch.searchType.car'),
              },
            ]}
          />
          <div className="hidden laptop:block">
            <Help />
          </div>
        </div>
        <div className="hidden laptop:absolute laptop:bottom-0 laptop:right-0 laptop:block laptop:leading-[0px]">
          <img src={mainSearchTyre.src} role="presentation" />
        </div>
      </div>
      {searchType === 'size' && <SearchBySize query={query} smallSelect={smallSelect} initialData={selectedTyre} />}
      {searchType === 'car' && <SearchByCar query={query} initialData={selectedCar} />}
      <div className="mt-4 flex justify-center laptop:hidden">
        <Help />
      </div>
    </div>
  );
};
