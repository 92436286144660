import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import { schemas } from '@/network/client';

export const useGroupSelectCarProducers = (producers: schemas['Make'][]) => {
  const t = useTranslations();
  const groups = useMemo(() => {
    const popular: { value: string; label: string }[] = [];
    const rest: { value: string; label: string }[] = [];
    producers.forEach((producer) => {
      if (producer.is_popular) {
        popular.push({ value: producer.slug, label: producer.shop_name || '' });
      } else {
        rest.push({ value: producer.slug, label: producer.shop_name || '' });
      }
    });

    return [
      {
        label: t('selectCar.producers.popularGroup'),
        options: popular,
      },
      {
        label: t('selectCar.producers.restGroup'),
        options: rest,
      },
    ];
  }, [producers]);

  return groups;
};
