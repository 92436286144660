export { Modal } from './Modal';
export { ModalContent } from './ModalContent';
export { ModalClose } from './ModalClose';

// export const ModalContent = dynamic(() => import('./ModalContent').then((mod) => mod.ModalContent), {
//   ssr: false,
// });
// export const Modal = dynamic(() => import('./Modal').then((mod) => mod.Modal), {
//   ssr: true,
// });
// export const ModalClose = dynamic(() => import('./ModalClose').then((mod) => mod.ModalClose), {
//   ssr: false,
// });

export { useModal } from './useModal';
