import { EmblaCarouselType } from 'embla-carousel';

import { BannersPagination } from '@/components/BannersPagination';
import { schemas } from '@/network/client';

import { useCarouselDots } from './useCarouselDots';

interface Props {
  emblaApi: EmblaCarouselType | undefined;
  data: schemas['MainPageBanner'][];
}

export const CarouselBannersPagination = (props: Props) => {
  const { emblaApi, data } = props;
  const { selectedIndex, onDotButtonClick } = useCarouselDots(emblaApi);

  const paginationOnMouseEnter = (index: number) => {
    onDotButtonClick(index);
    emblaApi?.plugins().autoplay.stop();
  };
  const paginationOnMouseExit = () => {
    emblaApi?.plugins().autoplay.play();
  };

  return (
    <BannersPagination
      data={data}
      onClick={onDotButtonClick}
      activeIndex={selectedIndex}
      itemOnMouseEnter={paginationOnMouseEnter}
      itemOnMouseExit={paginationOnMouseExit}
    />
  );
};
