import { createFormatter, NumberFormatOptions } from 'next-intl';

export const MAX_PRODUCT_RATING = 5;

export const useProductRate = () => {
  const format = createFormatter({
    locale: 'en-US',
  });
  const getRating = (value: string | number | null | undefined, options?: NumberFormatOptions) => {
    const rating = Number(value) || 0;
    return {
      ratingAsStr: format.number(rating, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
        ...options,
      }),
      ratingAsNumber: rating,
    };
  };
  return {
    getRating,
  };
};
