import useEmblaCarousel from 'embla-carousel-react';
import Image from 'next/image';
import Link from 'next/link';

import { schemas } from '@/network/client';
import { cn } from '@/utils/styles.utils';

interface Props {
  producers: schemas['MainPageProducer'][];
}

export const TyresSectionProducersCarousel = (props: Props) => {
  const { producers } = props;
  const [emblaRef] = useEmblaCarousel({
    align: 'start',
    duration: 20,
  });

  return (
    <div className="relative">
      <div className="overflow-hidden" ref={emblaRef}>
        <div
          className={cn([
            'flex',
            {
              '-ml-2 [&>*]:flex-[0_0_50%] [&>*]:pl-2': true,
              'tablet:-ml-3 tablet:[&>*]:flex-[0_0_calc(100%/3)] tablet:[&>*]:pl-3': true,
              'laptop:-ml-6 laptop:[&>*]:flex-[0_0_calc(100%/6)] laptop:[&>*]:pl-6': true,
            },
          ])}
        >
          {producers.map((item) => (
            <div key={item.path}>
              <Link href={item.path} key={item.path} className="flex h-32 items-center justify-center px-4">
                {item.logo && (
                  <Image
                    quality={100}
                    alt={item.name}
                    src={item.logo}
                    width={150}
                    height={100}
                    style={{
                      objectFit: 'contain',
                    }}
                  />
                )}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
