import { VariantProps, cva } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import React from 'react';

import { Icons } from '@/components/_atoms/Icon';
import { Color } from '@/types/colors.types';
import { MessagesKey } from '@/types/translations.types';
import { cn } from '@/utils/styles.utils';

import { ButtonContent } from './ButtonContent';

export type ButtonSize = 'xs' | 's' | 'm' | 'l';

export const buttonVariants = cva(
  'relative inline-flex items-center justify-center text-text-grey-dark transition-colors disabled:pointer-events-none data-[square=true]:p-0',
  {
    variants: {
      variant: {
        primary:
          'bg-orange-500 hover:bg-orange-400 disabled:bg-grey-400 disabled:text-text-grey-light data-[active-change-color=true]:active:bg-orange-600',
        secondary:
          'bg-grey-1000 text-text-white hover:bg-grey-900 disabled:bg-grey-400 disabled:text-text-grey-light data-[active-change-color=true]:active:bg-grey-800',
        subtle:
          'border border-solid border-grey-500 bg-white hover:border-orange-500 disabled:bg-grey-400 disabled:text-text-grey-light data-[destructive=true]:text-text-red data-[active-change-color=true]:active:bg-grey-200',
        transparent:
          'bg-[transparent] hover:bg-grey-100 disabled:text-text-grey-light disabled:opacity-50 data-[destructive=true]:text-text-red data-[active-change-color=true]:active:bg-grey-200',
      },
      size: {
        xs: 'h-6 space-x-1 rounded-[0.25rem] px-3 data-[square=true]:w-6',
        s: 'h-8 space-x-1 rounded-[0.25rem] px-3 data-[square=true]:w-8',
        m: 'h-12 rounded-lg px-6 data-[square=true]:w-12',
        l: 'h-14 rounded-lg px-6 data-[square=true]:w-14',
      },
    },
    defaultVariants: {
      size: 'm',
      variant: 'primary',
    },
  },
);

export type ButtonVariants = typeof buttonVariants;

export interface BaseButtonProps extends VariantProps<ButtonVariants> {
  text?: string;
  textId?: MessagesKey;
  iconName?: Icons;
  iconColor?: Color;
  color?: 'standard' | 'destructive';
  values?: any;
  iconBadge?: number;
  loading?: boolean;
  changeColorOnActive?: boolean;
}

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & BaseButtonProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const t = useTranslations();
  const {
    variant = 'primary',
    size,
    onClick,
    iconName,
    iconColor = 'grey-100',
    color = 'standard',
    text,
    textId,
    className,
    values,
    iconBadge,
    loading = false,
    changeColorOnActive = true,
    ...restProps
  } = props;

  const isOnlyIcon = iconName && !text && !textId;
  const contentText = textId ? t(textId, values) : text;
  return (
    <button
      ref={ref}
      onClick={!loading ? onClick : undefined}
      className={cn(buttonVariants({ variant, size }), className)}
      data-active-change-color={changeColorOnActive}
      data-destructive={color === 'destructive'}
      data-square={isOnlyIcon}
      {...restProps}
    >
      <ButtonContent
        iconColor={iconColor}
        color={color}
        iconBadge={iconBadge}
        iconName={iconName}
        loading={loading}
        size={size}
        variant={variant}
        text={contentText}
      />
    </button>
  );
});

Button.displayName = 'Button';
