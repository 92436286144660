import Link from 'next/link';

import { Typography } from '@/components/_atoms/Typography';
import { ProductSeasonIcon } from '@/components/_molecules/ProductSeasonIcon';
import { schemas } from '@/network/client';

import { TyresSectionProducersCarousel } from './TyresSectionProducersCarousel';
import { TyresSectionWhen } from './TyresSectionWhen';

interface Props {
  producers: schemas['MainPageProducer'][];
}

export const TyresSection = (props: Props) => {
  const { producers } = props;
  return (
    <div className="space-y-8 laptop:space-y-10">
      <div className="space-y-4 laptop:space-y-6">
        <Typography as="h1" bold id="homeScreen.tyres.header" className={'text-header-l laptop:text-header-xl'} />
        <Typography as="p" bold id="homeScreen.tyres.description" className={'text-body-l laptop:text-body-xl'} />
      </div>
      <div className="space-y-4">
        <Typography
          as="h2"
          bold
          id="homeScreen.tyres.cheapTyres.header"
          className={'text-body-xxl laptop:text-header-l'}
        />
        <div className="space-y-2">
          <Typography
            as="p"
            id="homeScreen.tyres.cheapTyres.description1"
            values={{
              continental: (msg: Array<string>) => (
                <Link href={'/opony/continental'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
              michelin: (msg: Array<string>) => (
                <Link href={'/opony/michelin'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
              hankook: (msg: Array<string>) => (
                <Link href={'/opony/hankook'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
              debica: (msg: Array<string>) => (
                <Link href={'/opony/debica'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
              uniroyal: (msg: Array<string>) => (
                <Link href={'/opony/uniroyal'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
              goodyear: (msg: Array<string>) => (
                <Link href={'/opony/goodYear'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
              bridgestone: (msg: Array<string>) => (
                <Link href={'/opony/bridgestone'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
            }}
            className={'text-body-m laptop:text-body-l'}
          />
          <Typography
            as="p"
            id="homeScreen.tyres.cheapTyres.description2"
            className={'text-body-m laptop:text-body-l'}
          />
          <Typography
            as="p"
            id="homeScreen.tyres.cheapTyres.description3"
            className={'text-body-m laptop:text-body-l'}
          />
          <Typography
            as="p"
            id="homeScreen.tyres.cheapTyres.description4"
            className={'text-body-m laptop:text-body-l'}
          />
        </div>
        {/* <div className="grid grid-cols-2 gap-2 laptop:gap-6">
          <Image src="/store1.jpg" width={600} height={350} alt="" className="rounded-lg" />
          <Image src="/store2.jpg" width={600} height={350} alt="" className="rounded-lg" />
        </div> */}
        <div className="space-y-2">
          <Typography
            as="p"
            id="homeScreen.tyres.cheapTyres.description5"
            className={'text-body-m laptop:text-body-l'}
            values={{
              premiumContact: (msg: Array<string>) => (
                <Link href={'/model-opony/continental-premiumcontact-7'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
              contiWinterContact: (msg: Array<string>) => (
                <Link href={'/model-opony/continental-wintercontact-ts-870'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
            }}
          />
          <Typography
            as="p"
            id="homeScreen.tyres.cheapTyres.description6"
            className={'text-body-m laptop:text-body-l'}
            values={{
              primacy: (msg: Array<string>) => (
                <Link href={'/model-opony/continental-premiumcontact-7'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
              alpine: (msg: Array<string>) => (
                <Link href={'/model-opony/continental-wintercontact-ts-870'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
            }}
          />
          <Typography
            as="p"
            id="homeScreen.tyres.cheapTyres.description7"
            className={'text-body-m laptop:text-body-l'}
            values={{
              nokian: (msg: Array<string>) => (
                <Link href={'/opony/nokian'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
              barum: (msg: Array<string>) => (
                <Link href={'/opony/barum'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
              kleber: (msg: Array<string>) => (
                <Link href={'/opony/kleber'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
              nexen: (msg: Array<string>) => (
                <Link href={'/opony/nexen'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
              kumho: (msg: Array<string>) => (
                <Link href={'/opony/kumho'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
              pirelli: (msg: Array<string>) => (
                <Link href={'/opony/pirelli'} className="text-blue-500 underline">
                  {msg}
                </Link>
              ),
            }}
          />
          <TyresSectionProducersCarousel producers={producers} />
        </div>
        <Typography as="h2" bold id="homeScreen.tyres.shop.header" className={'text-body-xxl laptop:text-header-l'} />
        <div className="columns-1 gap-2 laptop:columns-2 laptop:gap-6">
          <Typography as="p" id="homeScreen.tyres.shop.description1" className={'text-body-m laptop:text-body-l'} />
          <Typography as="p" id="homeScreen.tyres.shop.description2" className={'text-body-m laptop:text-body-l'} />
        </div>
      </div>
      <div className="space-y-4 laptop:space-y-6">
        <div className="space-y-4">
          <Typography as="h2" bold id="homeScreen.tyres.when.header" className={'text-body-xxl laptop:text-header-l'} />
          <Typography as="p" id="homeScreen.tyres.when.description1" className={'text-body-m laptop:text-body-l'} />
        </div>
        <TyresSectionWhen />
        <Typography as="p" id="homeScreen.tyres.when.description2" className={'text-body-m laptop:text-body-l'} />
      </div>
      <div>
        <Typography
          as="h2"
          bold
          id="homeScreen.tyres.sell.header"
          className={'mb-4 text-body-xxl laptop:text-header-l'}
        />
        <div className="grid grid-cols-1 gap-2 laptop:grid-cols-2 laptop:gap-6">
          <div className="space-y-2">
            <Typography as="p" id="homeScreen.tyres.sell.description1" className={'text-body-m laptop:text-body-l'} />
            <Typography as="p" id="homeScreen.tyres.sell.description2" className={'text-body-m laptop:text-body-l'} />
            <Typography as="p" id="homeScreen.tyres.sell.description3" className={'text-body-m laptop:text-body-l'} />
            <Typography as="p" id="homeScreen.tyres.sell.description4" className={'text-body-m laptop:text-body-l'} />
          </div>
          <div className="divide-y divide-grey-400 py-4 laptop:py-0">
            <div className="flex gap-2 pb-6">
              <div className="shrink-0">
                <ProductSeasonIcon season="letnie" />
              </div>
              <Typography
                as="p"
                id="homeScreen.tyres.sell.descriptionSummer"
                className={'text-body-m laptop:text-body-l'}
              />
            </div>
            <div className="flex gap-2 py-6">
              <div className="shrink-0">
                <ProductSeasonIcon season="zimowe" />
              </div>
              <Typography
                as="p"
                id="homeScreen.tyres.sell.descriptionWinter"
                className={'text-body-m laptop:text-body-l'}
              />
            </div>
            <div className="flex gap-2 pt-6">
              <div className="shrink-0">
                <ProductSeasonIcon season="całoroczne" />
              </div>
              <Typography
                as="p"
                id="homeScreen.tyres.sell.descriptionAllSeason"
                className={'text-body-m laptop:text-body-l'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
