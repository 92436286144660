import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';

import { useLogoutMutation } from '@/network/_mutations/auth.mutations';

interface LogoutArg {
  redirect?: boolean;
  displayToast?: boolean;
}

export const useLogout = () => {
  const mutation = useLogoutMutation();
  const router = useRouter();
  const t = useTranslations();

  const logout = (data?: LogoutArg) => {
    const { redirect = true, displayToast = true } = data || {};
    return mutation.mutate(undefined, {
      onSuccess: async () => {
        if (redirect) {
          await router.push('/');
        }
        if (displayToast) {
          toast.success(t('logout.successToast'));
        }
      },
    });
  };

  return logout;
};
