import { unwrapResponse } from '../client';
import { routesClient, routesSchemas } from '../client-routes';

// Use only on client site due to request to custom api routes
// https://nextjs.org/docs/pages/building-your-application/data-fetching/get-server-side-props#getserversideprops-or-api-routes

const getUser = () => routesClient.GET('/api/account/user').then(unwrapResponse);
const updateUser = (data: routesSchemas['UserUpdateRequest']) =>
  routesClient.PUT('/api/account/user', { body: data }).then(unwrapResponse);
const deleteUser = () => routesClient.DELETE('/api/account/user').then(unwrapResponse);

const changePassword = (data: routesSchemas['ChangePasswordRequest']) =>
  routesClient
    .POST('/api/account/change-password', {
      body: data,
    })
    .then(unwrapResponse);

const signIn = (data: routesSchemas['SignInRequest']) =>
  routesClient.POST('/api/auth/signin', { body: data }).then(unwrapResponse);
const signOut = () => routesClient.POST('/api/auth/signout').then(unwrapResponse);

const getSession = () => routesClient.GET('/api/auth/session').then(unwrapResponse);

const createOrder = (data: routesSchemas['CreateOrder']) =>
  routesClient.POST('/api/order', { body: data }).then(unwrapResponse);

export const AuthApi = {
  // user
  getUser,
  updateUser,
  deleteUser,
  changePassword,
  // auth
  signIn,
  signOut,
  getSession,
  // account orders
  accountOrderInvoiceUrl: (orderId: string) => `/api/account/orders/${orderId}/invoice`,
  // orders
  createOrder,
};
