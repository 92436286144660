import { useRouter } from 'next/router';

import { ObjectFilters } from '@/logic/filters/filters.types';
import sizesStaticData from '@/static/sizes.static.json';
import { FILTER_PRODUCER_ID, FILTER_SEASON_ID, FILTER_SIZE_ID } from '@/types/TyreSearch.types';
import { isEmptyObject } from '@/utils/object.utils';
import { getPageObject } from '@/utils/router.utils';

import { getCategoryPagePath } from '../searchParams/searchParams.utils';

import { getOrderingObject } from './tyresListing.utils';

type NavigateParams = {
  query: ObjectFilters;
  page?: number;
  ordering?: string;
};

// Wcześniej było navigate na seo page ('/opony/') w zalezosci od wybranych filtrów
// Aktualnie zawsze kierujemy na /szukaj-opony
const NAVIGATE_TO_SEO_PAGES = false;

const getSizeSlug = (size: string) => {
  const sizeArray = (sizesStaticData.sizes as Record<string, string[]>)[size] || null;
  if (!sizeArray) {
    return null;
  }
  const [width, profile, rim] = sizeArray;
  return [width, profile, `r${rim}`].join('/').replace(/\./g, '-');
};

const getNoSeoPageUrl = (params: NavigateParams) => {
  const { query, page, ordering } = params;
  return {
    pathname: '/szukaj-opon',
    query: {
      ...query,
      ...getPageObject(page),
      ...getOrderingObject(ordering),
    },
  };
};

const getSeoPageUrl = (slugs: string[], page?: number) => {
  const pathname = getCategoryPagePath(slugs);
  const query = getPageObject(page);
  return {
    pathname,
    query,
  };
};

export function useNavigateToTyrePage(shallow = true) {
  const router = useRouter();

  const goToNoSeoPage = (params: NavigateParams) => {
    router.push(getNoSeoPageUrl(params), undefined, { shallow });
  };

  const goToSeoPage = (slugs: string[], page?: number) => {
    router.push(getSeoPageUrl(slugs, page), undefined, { shallow });
  };

  const navigate = (params: NavigateParams) => {
    const { query, ordering, page } = params;
    const {
      [FILTER_SIZE_ID]: size,
      [FILTER_SEASON_ID]: seasons = [],
      [FILTER_PRODUCER_ID]: producers = [],
      ...rest
    } = query;

    const producersArray = (Array.isArray(producers) ? producers : [producers]).filter(
      (v): v is string => !!v && typeof v === 'string',
    );
    const seasonArray = (Array.isArray(seasons) ? seasons : [seasons]).filter(
      (v): v is string => !!v && typeof v === 'string',
    );

    const isRestQueryEmpty = isEmptyObject(rest);
    const sizeSlug = typeof size === 'string' ? getSizeSlug(size) : null;

    if (!NAVIGATE_TO_SEO_PAGES) {
      return goToNoSeoPage(params);
    }

    if (!!ordering || !isRestQueryEmpty) {
      return goToNoSeoPage(params);
    }

    if (!!sizeSlug && producersArray.length === 1 && !seasonArray.length) {
      return goToSeoPage([producersArray[0] || '', sizeSlug], page);
    }

    if (!!sizeSlug && producersArray.length === 0 && seasonArray.length === 1) {
      return goToSeoPage([...seasonArray, sizeSlug], page);
    }

    if (!!sizeSlug && producersArray.length === 0 && !seasonArray.length) {
      return goToSeoPage([sizeSlug], page);
    }

    return goToNoSeoPage(params);
  };

  return navigate;
}
