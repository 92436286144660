import * as Dialog from '@radix-ui/react-dialog';
import { AnimatePresence, m } from 'framer-motion';
import React, { PropsWithChildren } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import { cn } from '@/utils/styles.utils';

import { useModalContext } from './modal.context';

export const ModalContent = ({
  children,
  className,
  contentClassName,
}: PropsWithChildren & { className?: string; contentClassName?: string }) => {
  const { isOpen } = useModalContext();
  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog.Portal forceMount>
          <RemoveScroll>
            <m.div
              className={cn(
                'fixed inset-0 z-[300] grid place-items-center overflow-y-auto bg-black/35 py-6',
                className,
              )}
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1 },
                collapsed: { opacity: 0 },
              }}
              transition={{ duration: 0.2 }}
            >
              <Dialog.Title hidden />
              <Dialog.Content forceMount className={contentClassName}>
                {children}
              </Dialog.Content>
            </m.div>
          </RemoveScroll>
        </Dialog.Portal>
      )}
    </AnimatePresence>
  );
};
