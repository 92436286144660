import { queryOptions, useQuery } from '@tanstack/react-query';

import { QueryKeys } from '@/network/query.types';

import { client, unwrapResponse } from '../client';

export const fetchMainPageQueryOptions = () =>
  queryOptions({
    queryKey: [QueryKeys.mainPage],
    queryFn: async () => {
      const [mainPage, banners] = await Promise.all([
        client.GET('/api/v1/main_page').then(unwrapResponse),
        client.GET('/api/v1/main_page/banners').then(unwrapResponse),
      ]);

      return {
        ...mainPage,
        banners,
      };
    },
  });

export function useMainPageQuery() {
  return useQuery({
    ...fetchMainPageQueryOptions(),
    refetchOnWindowFocus: true,
  });
}
