import { useRouter } from 'next/router';

import { getPathWithDomain } from '@/utils/router.utils';

interface Options {
  keepPage?: boolean;
}

const cleanUrl = (url: string) => url.split('#')[0]?.split('?');

export const useCanonical = (options?: Options) => {
  const router = useRouter();
  const { query } = router;
  const { keepPage = false } = options || {};
  const [url] = cleanUrl(router.asPath) || [];
  let canonicalUrl = url;
  if (keepPage && query.page) {
    const page = Number(query.page) || 0;
    if (page > 1) {
      canonicalUrl += `?page=${page}`;
    }
  }
  return getPathWithDomain(canonicalUrl);
};
