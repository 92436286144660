import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';

import { Icon } from '@/components/_atoms/Icon';
import { Typography } from '@/components/_atoms/Typography';
import { Li, Ul } from '@/components/_atoms/Ul';

import { InfoMessage } from '../InfoMessage';
import { Modal, ModalContent, useModal } from '../Modal';
import { ModalCard } from '../ModalCard';

import { HelpParameter } from './HelpParameter';
import tyreParametersDesktopImg from './tyre_parameters_desktop.webp';
import tyreParametersMobileImg from './tyre_parameters_mobile.webp';

const parameters = ['width', 'profile', 'rim', 'loadIndex', 'speedIndex'] as const;

export const Help = () => {
  const t = useTranslations();
  const modal = useModal();
  return (
    <>
      <button className="group flex items-center gap-2" onClick={modal.open}>
        <Icon name="questionMark" className="fill-grey-600 transition-colors group-hover:fill-grey-800" />
        <Typography
          id="help.text"
          className="text-text-grey-standard transition-colors group-hover:text-text-grey-dark"
        />
      </button>
      <Modal isOpen={modal.isOpen} onOpenChange={modal.setIsOpen}>
        <ModalContent>
          <ModalCard close={modal.close} size="l" className="overflow-hidden p-0">
            <div className="relative isolate flex flex-col gap-2 bg-grey-200 p-6 pb-0 tablet:min-h-[436px] tablet:gap-6 tablet:pb-6">
              <Typography variant="body_xxl" bold>
                {t('help.parametersHeader')}
              </Typography>
              <ul className="-mx-6 grid items-start tablet:m-0 tablet:grid-cols-3 tablet:gap-6">
                {parameters.map((parameter, index) => (
                  <HelpParameter
                    key={parameter}
                    index={index}
                    title={t(`help.${parameter}.header`)}
                    description={t(`help.${parameter}.description`)}
                  />
                ))}
              </ul>
              <img
                className="pointer-events-none absolute bottom-0 right-0 z-[-1] hidden tablet:inline-block"
                role="presentation"
                src={tyreParametersDesktopImg.src}
              />
              <InfoMessage
                text={t.rich('help.parametersInfo', {
                  strong: (msg: ReactNode) => <strong>{msg}</strong>,
                })}
              />
              <div className="pointer-events-none z-[-1] -mx-6 tablet:hidden">
                <img role="presentation" src={tyreParametersMobileImg.src} className="w-full" />
              </div>
            </div>
            <div className="p-6">
              <Typography variant="body_xl" bold className="mb-4">
                {t('help.extraParameters.header')}
              </Typography>
              <div className="grid gap-6 laptop:grid-cols-2">
                <div>
                  <Typography variant="body_m" bold className="mb-1">
                    {t('help.xl.header')}
                  </Typography>
                  <Typography variant="body_m" color="grey-standard">
                    {t('help.xl.description')}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body_m" bold className="mb-1">
                    {t('help.runFlat.header')}
                  </Typography>
                  <Typography variant="body_m" color="grey-standard">
                    {t('help.runFlat.description')}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="border-t border-grey-300 p-6">
              <Typography variant="body_xl" bold className="mb-4">
                {t('help.size.header')}
              </Typography>
              <Ul className="grid gap-x-6 gap-y-2 laptop:grid-cols-2">
                {([1, 2, 3, 4, 5, 6] as const).map((item) => (
                  <Li key={item}>
                    <Typography color="grey-standard" as="span">
                      {t(`help.size.list._${item}`)}
                    </Typography>
                  </Li>
                ))}
              </Ul>
            </div>
          </ModalCard>
        </ModalContent>
      </Modal>
    </>
  );
};
