import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiErrorCodes } from '../api.utils';
import { AuthApi } from '../authApi';
import { client, schemas, unwrapResponse } from '../client';
import { QueryKeys } from '../query.types';

export const useLoginMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: AuthApi.signIn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.session],
      });
    },
  });
};

export const useLogoutMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => AuthApi.signOut(),
    onSuccess: () => {
      // Clear cache to not have old logged user data in cache
      queryClient.clear();
    },
  });
};

export const useRegisterMutation = () => {
  return useMutation({
    mutationFn: (data: schemas['Registration']) =>
      client.POST('/api/v1/auth/register', { body: data }).then(unwrapResponse),
    meta: {
      captureException: true,
      excludeErrorCodes: Object.values(ApiErrorCodes.register),
    },
  });
};

export const useForgotPasswordMutation = () => {
  return useMutation({
    mutationFn: (data: schemas['Email']) =>
      client.POST('/api/v1/auth/password_reset', { body: data }).then(unwrapResponse),
    meta: {
      captureException: true,
      excludeErrorCodes: Object.values(ApiErrorCodes.forgotPassword),
    },
  });
};

export const useResetPasswordMutation = () => {
  return useMutation({
    mutationFn: (data: schemas['PasswordToken']) =>
      client.POST('/api/v1/auth/password_reset/confirm', { body: data }).then(unwrapResponse),
    meta: {
      captureException: true,
    },
  });
};
