import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useState } from 'react';

import { Icon } from '@/components/_atoms/Icon';
import { Spinner } from '@/components/_atoms/Spinner';
import { Typography } from '@/components/_atoms/Typography';
import { CheckboxGroup } from '@/components/_molecules/CheckboxGroup';
import { ProductSeasonIcon } from '@/components/_molecules/ProductSeasonIcon';
import { SelectCarValueRequired } from '@/logic/selectCar';
import { useSelectCarSizes } from '@/logic/selectCar/useSelectCarSizes';
import { schemas } from '@/network/client';
import { FILTER_SEASON_ID } from '@/types/TyreSearch.types';

import { SelectCarHelp } from './components/SelectCarHelp';
import { SelectCarSizeItem } from './components/SelectCarSizeItem';

interface Props {
  data: SelectCarValueRequired;
  onSelectSize: (id: string, data: schemas['CarSizeDetail']) => void;
  onClose: () => void;
}

export const SelectCarSizeModal = (props: Props) => {
  const t = useTranslations();
  const { data, onSelectSize, onClose } = props;
  const { carData, isLoading } = useSelectCarSizes(data);

  const [seasons, setSeasons] = useState<string[]>([]);

  const onSelect = (id: string, data: schemas['CarSizeDetail']) =>
    onSelectSize(id, {
      ...data,
      filters: {
        ...data.filters,
        [FILTER_SEASON_ID]: seasons,
      },
    });

  const seasonOptions = [
    {
      name: t('season.summer'),
      value: 'letnie' satisfies schemas['SeasonEnum'],
    },
    {
      name: t('season.winter'),
      value: 'zimowe' satisfies schemas['SeasonEnum'],
    },
    {
      name: t('season.allSeason'),
      value: 'całoroczne' satisfies schemas['SeasonEnum'],
    },
  ];
  return (
    <div className="relative my-6 min-h-[300px] w-[calc(100vw-1.5rem)] rounded-lg bg-white p-3 tablet:w-[544px] tablet:p-6">
      <Spinner center visible={isLoading} overflow />
      {carData && (
        <>
          <button className="absolute right-4 top-4 z-[1]" onClick={onClose}>
            <Icon name="close" />
          </button>
          <div className="mb-2 flex">
            <div className="mt-6 flex-1">
              <Typography variant="body_xxl" bold>
                {carData.car_name}
              </Typography>
              <Typography variant="body_m" color="grey-standard">
                {carData.car_specification}
              </Typography>
            </div>
            <div className="mr-4">
              <Image
                unoptimized
                role="presentation"
                alt={carData.car_name}
                src={carData.car_image}
                width={200}
                height={100}
                className="h-[100px] w-[200px]"
                style={{
                  objectFit: 'contain',
                  objectPosition: 'right',
                }}
              />
            </div>
          </div>
          <div className="mb-6">
            <Typography id="selectCarSizeModal.selectSeason" variant="body_l" bold className="mb-4" />
            <CheckboxGroup
              options={seasonOptions}
              value={seasons}
              onChange={setSeasons}
              renderChildren={(option) => <ProductSeasonIcon season={option.value} size={16} />}
            />
          </div>
          <Typography id="selectCarSizeModal.selectHeader" variant="body_l" bold className="mb-1" />
          <Typography
            id="selectCarSizeModal.selectCopy"
            values={{
              producer: carData.make,
            }}
            variant="body_m"
            color="grey-standard"
            className="mb-6"
          />
          <SelectCarHelp />
          <ul className="space-y-1 rounded-lg bg-grey-200 p-2">
            {carData.sizes.map((size, index) => (
              <li key={size.id}>
                <SelectCarSizeItem {...size} onClick={onSelect} index={index} />
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};
